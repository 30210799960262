import { Box, Divider, Group, List, NumberFormatter, Paper, Rating, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { config } from '../../lib/config-candidate';
import { useWizardFormContext } from "../form/wizard-form-context";
import ReviewRatingDisplay from "./Reviews/ReviewRatingDisplay";


export default function AboutUs() {

  const { reviewsRating, reviewsCount } = useWizardFormContext();
  const fuzzyReviewCount = `${(reviewsCount * (reviewsRating/5)).toString().charAt(0)},000+`;

  return (
    <Paper p="xl" radius="md" bg="gray.0" shadow="none">
      <Title order={2} size="h4" fz="h4">About {config.brand.name}</Title>
      <ReviewRatingDisplay reviewsRating={reviewsRating} reviewsCount={reviewsCount} mb="sm" align="center" />
      <Stack gap="lg">
        <Box>
          <Text fz="sm" my="md" c="dimmed">With {fuzzyReviewCount} 5-star reviews, {config.brand.name} is your trusted local cleaning service. We specialize in delivering exceptional cleaning for homes and businesses across the Baltimore area. Our dedicated team is committed to excellence and ensuring your satisfaction every time.</Text>
          <Text fz="sm" c="dimmed">Note: <strong>Exclusions apply.</strong> This offer does not cover moving heavy items, outdoor cleaning, pet waste removal, mold remediation, deep stain cleaning, blinds, or areas outside of normal reach.</Text>
        </Box>
        <Divider />
        <Box>
          <Title order={5} fz={{ base: 'md', md: 'lg' }}>About This Offer</Title>
          <Text fz="sm" c="dimmed">Valid for new clients only. Online booking required. 24-hour cancellation policy applies. Fully refundable until used or expiration. Voucher expires after 1 year. Discount voucher requires an {config.pricingAndPromotion.membership.name} membership, granting access to future cleanings at over 50% off. Membership is billed at $<Text span inherit data-testid="membership-rate">{config.pricingAndPromotion.membership.monthlyRate.amount.toString()}</Text>/month (taxes may apply). Cancel anytime, but early cancellations (before <Text span inherit data-testid="membership-minimum-months">{config.pricingAndPromotion.membership.minimumDurationMonths.toString()}</Text> paid months) will result in your first cleaning being charged at full price.</Text>
        </Box>
        <Divider />
        <Box>
          <Title order={5} fz={{ base: 'md', md: 'lg' }}>Why {config.brand.name}</Title>
          <List c="dimmed">
            <List.Item><Text fz="sm">{fuzzyReviewCount} 5-star reviews</Text></List.Item>
            <List.Item><Text fz="sm">Locally owned and operated</Text></List.Item>
            <List.Item><Text fz="sm">Background-checked and trained staff</Text></List.Item>
            <List.Item><Text fz="sm">100% satisfaction guarantee</Text></List.Item>
            <List.Item><Text fz="sm">Trusted by thousands of Maryland residents</Text></List.Item>
          </List>
        </Box>
      </Stack>
    </Paper>
  );
}