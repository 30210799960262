import { Anchor, Text } from "@mantine/core";
import { config } from "../../../../lib/config-candidate";

export default function OptInTermsText() {
  return (
    <Text size="xs" c="dimmed">
      By submitting, you confirm that you agree to our{' '}
      <Anchor
        href={config.legal.termsOfServiceUrl}
        target="_blank"
        rel="noopener noreferrer"
        c="dimmed"
        td="underline"
        fz="xs"
      >
        Terms of Service
      </Anchor>{' '}
      and{' '}
      <Anchor
        href={config.legal.privacyPolicyUrl}
        target="_blank"
        rel="noopener noreferrer"
        c="dimmed"
        td="underline"
        fz="xs"
      >
        Privacy Policy
      </Anchor>, and that we may contact you with marketing and promotional messages by email, phone, or automated SMS. You can unsubscribe at any time.
    </Text>
  );
}