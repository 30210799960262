// app/routes/index.jsx
import { Container, Grid, Text, Title, Paper, Stack, Box, BackgroundImage, TextInput, ActionIcon } from '@mantine/core';
import { useRef } from 'react';
import LandingFormWizard from './form/LandingFormWizard';
import Reviews from './sections/Reviews';
import type { WizardFormData } from './form/types';
import { WizardStepsProgress } from './form/Steps/WizardProgressBar';
import { useMediaQuery, useScrollIntoView } from '@mantine/hooks';
import { useWizardFormContext } from './form/wizard-form-context';
import ZipNotCovered from './ZipCodeCheck/ZipNotCoveredForm';
import ZipCoveredNotice from './ZipCodeCheck/ZipCoveredNotice';
import React from 'react';
import MembershipBenefits from './sections/MembershipBenefits';
import VoucherSelectionGuide from './sections/VoucherSelectionGuide';
import HowItWorks from './sections/HowItWorks';
import ServiceArea from './sections/ServiceArea';
import AboutUs from './sections/AboutUs';
import { config } from '../lib/config-candidate';

export default function Content({ sessionData }: { sessionData: Partial<WizardFormData> }) {

  const { form } = useWizardFormContext();
  const { getInputProps } = form;
  // This breakpoint determines "isMobile" for our layout adjustments
  const { activeStep, setActiveStep, checkZipCode, zipCodeAvailability, setZipCodeAvailability } = useWizardFormContext();
  const isMobile = useMediaQuery('(max-width: 62em)', false, {
    getInitialValueInEffect: false,
  });
  const requestFormRef = useRef<HTMLFormElement>(null);
  const { scrollIntoView, targetRef } = useScrollIntoView();

  const checkZipCodeAndScroll = async () => {
    const zipIsValid = await checkZipCode();
    if (zipIsValid) {
      setTimeout(() => {
        scrollIntoView({
          alignment: 'start',
        });
      }, 500);
    }
  }

  return (
    <div>
      {/* Hero Section */}
      {activeStep === 0 && (
        <BackgroundImage src='/images/hero.jpeg'>
          <Container component="section" size="lg" py={{ base: "4em", lg: "6em" }} mb="sm">
            <Grid justify="space-between" gutter="xl">
              {/* <Grid.Col span={{ base: 12, md: 6 }} offset={{ base: 0, md: 6 }}> */}
              <Grid.Col span={{ base: 12, md: 6 }} offset={{ base: 0, md: 6 }}>
                {false && zipCodeAvailability !== false && (
                  <Box mb="xl" ta="center">

                    <Text fz={{ base: "md", md: "lg" }} mt="md">Professional home cleaning at the best value, designed to fit your budget.</Text>
                  </Box>
                )}
                <Paper p="xl" shadow="sm" radius="md">
                  {zipCodeAvailability !== false && (
                    <>
                      <Title order={1} size="h2" fz={{ base: "h4", md: "h2" }} ta="left">Affordable Cleaning, Exceptional Results</Title>
                      <Text mb="md" fz={{ base: "md", md: "lg" }} ta="left">Enter your zip code to see if discounted cleanings are still available in your area!</Text>
                      <TextInput
                        radius="md"
                        size="lg"
                        ta="center"
                        placeholder="21201"
                        autoComplete="postal-code"
                        aria-label="Zip Code"
                        data-testid="check-zip-code-input"
                        rightSectionWidth={50}
                        rightSection={
                          <ActionIcon size={35} radius="md" color="green" variant="filled" onClick={checkZipCodeAndScroll} data-testid="check-zip-code-submit" aria-label="Check Zip Code">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-search"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                              <path d="M21 21l-6 -6" />
                            </svg>
                          </ActionIcon>
                        }
                        {...getInputProps('checkZipCode')}
                      />
                    </>
                  )}
                  {zipCodeAvailability === true && (
                    <Box mt="md">
                      <ZipCoveredNotice />
                    </Box>
                  )}
                  {zipCodeAvailability === false && (
                    <ZipNotCovered />
                  )}
                </Paper>
              </Grid.Col>
            </Grid>
          </Container>
        </BackgroundImage>
      )}

      {/* Main Content */}
      <Container size="lg" py="lg">
        <Box pb="lg" maw={{ base: '100%', md: '80%' }} m="auto" ref={targetRef}>
          {activeStep !== 0 && (
            <WizardStepsProgress
              isMobile={isMobile}
            />
          )}
        </Box>
        <Grid gutter="xl">
          <Grid.Col span={{ base: 12, md: 7 }} order={{ base: 2, md: 1 }}>
            <Stack gap="xxl">

              {activeStep > 1 && (
                <MembershipBenefits />
              )}

              <Reviews />

              <VoucherSelectionGuide />

              <HowItWorks />

              {activeStep === 0 && (
                <ServiceArea />
              )}

              <AboutUs />

            </Stack>
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 5 }} order={{ base: 1, md: 2 }}>
            <Box style={{ position: 'sticky', top: '20px' }} id="landing-form-wizard">
              <LandingFormWizard isMobile={isMobile} />
            </Box>
          </Grid.Col>
        </Grid>
      </Container>

      {/* Footer */}
      <Box component="footer" py="xl" bg="gray.0" mb={{base: 145, md:0}}>
        <Container>
          <Text c="dimmed" ta="center" fz="xs">&copy; {config.legal.copyright.year.toString()} {config.legal.copyright.owner}</Text>
        </Container>
      </Box>
    </div>
  );
}