import { Anchor, Box, Button, Divider, Group, List, Modal, Paper, ScrollArea, Stack, Text, TextInput } from "@mantine/core";
import { config } from '../../lib/config-candidate';
import PageSubHeader from "../Typography/PageSubHeader";
import { useDisclosure, useInputState } from "@mantine/hooks";
import { useEffect, useMemo, useState } from "react";
import ModalBase from "../ModalBase";

export default function WhatsIncluded() {

  const [opened, { open, close }] = useDisclosure();
  const [zipRateCheck, setZipRateCheck] = useState(false);
  const [zipCode, setZipCode] = useInputState('');
  const checkZipCode = () => {
    const allowed = config.zipCodeServiceArea.allowedZipCodes.includes(zipCode);
    if (allowed) {
      setZipRateCheck(true);
    }
  }

  const listIcon = <Text c="green" span>✓</Text>;
  const listCleaning = () => {
    return (
      <List spacing="sm" size="sm" mb={0} pl={0} icon={listIcon}>
        <List.Item><strong>Cleaning supplies:</strong> including vacuum</List.Item>
        <List.Item><strong>Kitchen:</strong> dishes, microwave, appliance exteriors</List.Item>
        <List.Item><strong>Bathroom:</strong> tub, shower, toilet, sink, mirrors</List.Item>
        <List.Item><strong>Bedroom:</strong> making bed, folding laundry</List.Item>
        <List.Item><strong>Every Room:</strong> organizing, dusting, vacuuming</List.Item>
      </List>
    );
  }

  const listMembership = () => {
    return (
      <List spacing="sm" size="sm" mb={0} pl={0} icon={listIcon}>
        <List.Item>Plus, enjoy <strong><Text span inherit data-testid="membership-minimum-months">{config.pricingAndPromotion.membership.minimumDurationMonths.toString()}</Text> Months of {config.pricingAndPromotion.membership.name} Membership</strong>, giving you access to unlimited discounted cleanings starting at $<Text span inherit data-testid="discounted-rate">{config.pricingAndPromotion.discountedRate.amount.toString()}</Text>/hr.<br /><Anchor onClick={open} td="underline" fw="bold" fz="sm" data-testid="membership-faq-link">Learn more</Anchor></List.Item>
        <List.Item><strong>Premium services</strong> like wash & fold laundry, inside fridge & oven cleaning, cabinet interiors, windows, and walls are included at no extra cost.</List.Item>
      </List>
    );
  }

  const FaqModalContent = () => {
    return (
      <Stack gap="md" data-testid="membership-faq-content">
        <Stack gap="sm">
          <Text fz="sm" fw={600}>What is the {config.pricingAndPromotion.membership.name} membership?</Text>
          <Text fz="sm">{config.pricingAndPromotion.membership.name} is a membership program that lets you book cleanings at our most affordable rates. With membership, you can book unlimited cleanings at more than 50% off. If you book even one cleaning a month, membership is a no-brainer.</Text>
        </Stack>

        <Divider />

        <Stack gap="sm">
          <Text fz="sm" fw={600}>How much will future cleanings cost with {config.pricingAndPromotion.membership.name}?</Text>
          {/* <Text fz="sm">Your first cleaning is covered by your discount voucher. After that, {config.pricingAndPromotion.membership.name} membership grants a $<Text span inherit data-testid="savings-amount">{(config.pricingAndPromotion.standardRate.amount - config.pricingAndPromotion.discountedRate.amount).toString()}</Text>/hr discount on our standard rates. Enter your zipcode below to compare standard rates with membership rates in your area.</Text> */}
          <Text fz="sm">Your first cleaning is covered by your discount voucher. After that, {config.pricingAndPromotion.membership.name} membership grants a $<Text span inherit data-testid="savings-amount">{(config.pricingAndPromotion.standardRate.amount - config.pricingAndPromotion.discountedRate.amount).toString()}</Text>/hr discount on our standard rates.</Text>
          {/* <TextInput placeholder="Enter your zip code" value={zipCode} onChange={setZipCode} /> */}
          {/* <Button variant="light" size="sm" onClick={checkZipCode} fullWidth>Compare Rates</Button> */}
          {/* {zipRateCheck && ( */}
            <Paper p="md" bg="yellow.0" radius="md" mt="xs">
              <Text fz="sm">
                <strong>Standard Rate:</strong> <Text span inherit data-testid="standard-rate">${config.pricingAndPromotion.standardRate.amount.toString()}</Text>/hr<br />
                <strong>Membership Rate:</strong> <Text span inherit data-testid="discounted-rate">${config.pricingAndPromotion.discountedRate.amount.toString()}</Text>/hr
              </Text>
            </Paper>
          {/* )} */}
        </Stack>

        <Divider />

        <Stack gap="sm">
          <Text fz="sm" fw={600}>Can I cancel my membership?</Text>
          <Text fz="sm">Cancelling is straightforward at any time though the discount voucher does carry a minimum commitment term of <Text span inherit data-testid="membership-minimum-months">{config.pricingAndPromotion.membership.minimumDurationMonths.toString()}</Text> months. Cancelling before <Text span inherit data-testid="membership-minimum-months">{config.pricingAndPromotion.membership.minimumDurationMonths.toString()}</Text> paid months will result in your first cleaning being charged at full price.</Text>
        </Stack>

        <Divider />

        <Stack gap="sm">
          <Text fz="sm" fw={600}>How much does ongoing membership cost?</Text>
          <Text fz="sm">{config.pricingAndPromotion.membership.name} membership costs $<Text span inherit data-testid="membership-rate">{config.pricingAndPromotion.membership.monthlyRate.amount.toString()}</Text> per month (taxes may apply). Monthly membership billing doesn't begin until you redeem your voucher.</Text>
        </Stack>
      </Stack>
    );
  }

  return (
    <Paper p="lg" shadow="sm" radius="md" withBorder>
      <PageSubHeader mb="md">What's Included?</PageSubHeader>
      <Stack hiddenFrom="sm" gap="sm">
        {listCleaning()}
        {listMembership()}
      </Stack>
      <Group visibleFrom="sm" gap="md" align="start" grow>
        {listCleaning()}
        {listMembership()}
      </Group>
      <ModalBase opened={opened} setOpened={close} title={`${config.pricingAndPromotion.membership.name} Membership FAQ`}>
        <FaqModalContent />
      </ModalBase>

    </Paper>
  );
}