import { Box } from "@mantine/core";
import { useInViewport, useMediaQuery } from "@mantine/hooks";
import { ReactNode, useEffect } from "react";
import { useWizardFormContext } from "../wizard-form-context";
import { WizardStepsProgress } from "./WizardProgressBar";

const StepWrapper = ({ children }: { children: ReactNode }) => {
  const { activeStep } = useWizardFormContext();
  const isMobile = useMediaQuery('(max-width: 62em)', false, {
    getInitialValueInEffect: false,
  });
  const { ref, inViewport } = useInViewport();
  useEffect(() => {
    if (isMobile && inViewport && activeStep) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [inViewport, isMobile, activeStep]);
  return (
    // Mobile fullscreen container; visible only when activeStep is > 0
    <Box className={activeStep > 0 ? "mobile-step-wrapper" : ""} ref={ref}>
      {/* Scrollable content container for mobile fullscreen view */}
      <Box className={activeStep > 0 ? "mobile-scroll-container" : ""}>
        {/* Progress bar; visible only on mobile steps 2+ */}
        {activeStep > 0 && (
          <Box hiddenFrom="md" mb="md">
            <WizardStepsProgress isMobile={isMobile} withNumbers={false} />
          </Box>
        )}
        {/* Step content */}
        {children}
      </Box>
    </Box>
  );
};

export default StepWrapper;