import { ActionIcon, Box, TextInput } from "@mantine/core";
import { useWizardFormContext } from "../form/wizard-form-context";
import ZipCoveredNotice from "./ZipCoveredNotice";
import ZipNotCovered from "./ZipNotCoveredForm";
import React from 'react';

export default function ZipCodeFormCombined() {
  const { form, checkZipCode, zipCodeAvailability, setZipCodeAvailability } = useWizardFormContext();
  const { getInputProps } = form;

  const checkZipCodeAndScroll = async () => {
    await checkZipCode();
  }

  return (
    <>
      {zipCodeAvailability !== false && (
        <>
          <TextInput
            radius="md"
            size="lg"
            ta="center"
            placeholder="21201"
            autoComplete="postal-code"
            aria-label="Zip Code"
            data-testid="check-zip-code-input-combined"
            rightSectionWidth={50}
            rightSection={
              <ActionIcon size={35} radius="md" color="green" variant="filled" onClick={checkZipCodeAndScroll} data-testid="check-zip-code-submit-combined" aria-label="Check Zip Code">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-search"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                  <path d="M21 21l-6 -6" />
                </svg>
              </ActionIcon>
            }
            {...getInputProps('checkZipCode')}
          />
        </>
      )}
      {zipCodeAvailability === true && (
        <Box mt="md">
          <ZipCoveredNotice />
        </Box>
      )}
      {zipCodeAvailability === false && (
        <ZipNotCovered />
      )}
    </>
  );
}