import { Box, Stack } from "@mantine/core";
import TextInputMaterial from "../../../../components/form/inputs/TextInputMaterial";
import { useWizardFormContext } from "../../wizard-form-context";
import ExistingUserFoundNotice from "./ExistingUserFoundNotice";


export default function ContactInfoInputs() {
  const { form } = useWizardFormContext();
  const { getInputProps } = form;

  return (
    <Stack gap="sm">
      <TextInputMaterial
        label="First Name"
        placeholder="John"
        required
        autoComplete="given-name"
        {...getInputProps('contactInfo.firstName')}
      />

      <TextInputMaterial
        label="Last Name"
        placeholder="Doe"
        required
        autoComplete="family-name"
        {...getInputProps('contactInfo.lastName')}
      />

      <TextInputMaterial
        label="Email"
        type="email"
        placeholder="user@example.com"
        required
        autoComplete="email"
        {...getInputProps('contactInfo.email')}
      />

      <ExistingUserFoundNotice />

      <TextInputMaterial
        label="Phone"
        placeholder="4105555555"
        required
        autoComplete="tel"
        type="tel"
        {...getInputProps('contactInfo.phone')}
      />
    </Stack>
  );
}