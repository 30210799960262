import { Badge, Box, Center, Divider, Group, Image, Paper, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import WhatsIncluded from "./WhatsIncluded";
import PageHeader from "../Typography/PageHeader";


export default function HowItWorks() {

  const theme = useMantineTheme();

  const processCopy = [
    {
      title: "Purchase Discount Voucher",
      description: "Buy your discount voucher online and save up to 50% off our standard rates."
    },
    {
      title: "Schedule Your Cleaning Online",
      description: "Book your cleaning online in minutes. Choose your date and time, and we'll handle the rest."
    },
    {
      title: "Sit Back and Relax",
      description: "Enjoy a sparkling clean home. Our team will arrive on time and leave your home spotless."
    }
  ];

  const processSteps = processCopy.map((step, index) => {
    return (
      <Box key={index}>
        <Paper radius="md" shadow="none" visibleFrom="sm">
          <Center mb="sm">
            <Badge size="xl" fw={600} circle>
              {index + 1}
            </Badge>
          </Center>
          <Text fz="lg" ta="center" fw={600}>{step.title}</Text>
        </Paper>
        <Group key={`group-${index}`} hiddenFrom="sm" gap="sm" align="center" justify="flex-start">
          <Badge size="xl" fw={600} circle>
            {index + 1}
          </Badge>
          <Text fz="lg" ta="center" fw={600}>{step.title}</Text>
        </Group>
      </Box>
    );
  });

  return (
    <Box>
      {/* <Title order={2} fz={{base: "h3", md:"h2"}}>How We Do It</Title> */}
      <PageHeader mb="md">How It Works</PageHeader>
      <Stack gap="sm">
        <Group>
          <Image src="/images/Recurring_Home_Cleaning.gif" alt="How It Works" radius="md" style={{ maxWidth: "100%", height: "auto" }} loading="lazy" />
        </Group>
        <Paper py="md" shadow="none" radius="md">
          <Group gap="md" grow visibleFrom="sm" align="start">
            {processSteps}
          </Group>
          <Stack gap="md" hiddenFrom="sm">
            {processSteps}
          </Stack>
        </Paper>
        <WhatsIncluded />
      </Stack>
      <Divider mt="lg" />
    </Box>
  );
}