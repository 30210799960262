import { Box, Card, Center, Group, Rating, Stack, Text } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useWizardFormContext } from "../form/wizard-form-context";
import { Review } from "./Reviews";

type FeaturedReviewListProps = {
  list: Review[];
};

export default function FeaturedReview({ list }: FeaturedReviewListProps) {
  const { activeStep } = useWizardFormContext();

  const featuredReviews = list
    .filter((review) => review.review_featured)
    .filter((review) => review.review.length < 160)
    .sort((a, b) => new Date(b.review_date).getTime() - new Date(a.review_date).getTime())
    .slice(0, 10);

  if (activeStep > 1 || featuredReviews.length === 0) {
    return null;
  }

  return (
    <Box mb="xl">
      <Card padding={0} radius="lg" style={{ overflow: 'hidden' }} shadow="sm">
        <Carousel
          withControls={false}
          height={300}
          slideSize="100%"
          slideGap={0}
          align="start"
          loop
          controlsOffset="xs"
          styles={{
            control: {
              backgroundColor: 'white',
              border: 'none',
            },
            slide: {
              height: '300px'
            }
          }}
        >
          {featuredReviews.map((review) => (
            <Carousel.Slide key={review.id}>
              <Box style={{ display: 'flex', height: '100%' }}>
                <Box style={{
                  flex: '0 0 50%',
                  backgroundImage: `url(${review.image_url || '/images/review.jpeg'})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }} />
                
                <Box style={{ flex: '0 0 50%', padding: 'var(--mantine-spacing-md)' }}>
                  <Center style={{ height: '100%' }}>
                    <Stack align="center" justify="center" gap="md" w="100%">
                      <Rating size="lg" value={review.rating} readOnly />
                      <Text
                        ta="center"
                        fz={review.review.length < 150 ? { base: "md", sm: "lg" } : { base: "sm", sm: "md" }}
                        lh={1.6}
                      >
                        "{review.review}"
                      </Text>
                      <Group gap="xs" justify="center">
                        <Text ta="center" fz="xs" c="dimmed">
                          {review.customer_first_name.charAt(0).toUpperCase() +
                           review.customer_first_name.slice(1).toLowerCase()} {
                           review.customer_last_name.charAt(0).toUpperCase()}., {review.city || "MD"}
                        </Text>
                      </Group>
                    </Stack>
                  </Center>
                </Box>
              </Box>
            </Carousel.Slide>
          ))}
        </Carousel>
      </Card>
    </Box>
  );
}