import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Stepper,
  Group,
  Button,
  Box,
  Grid,
  Paper,
  Text,
  Stack,
} from "@mantine/core";
import { Form, useFetcher } from "@remix-run/react";
import { useWizardFormContext } from "./wizard-form-context";
import StepSelectVoucher from "./Steps/StepSelectVoucher";
import SurveyModal from "~/components/SurveyModal";
import StepContactInfo from "./Steps/StepContactInfo";
import StepNotices from "./Steps/StepNotices";
import StepPaymentCustom from "./Steps/StepPayment";
import SelectedProduct from "./SelectedProduct";
import { CountdownTimer } from "../CountdownTimer";
import { WizardStepsProgress } from "./Steps/WizardProgressBar";
import { wizardFormSchema } from "./validation";
import { z } from "zod";
import { useInViewport } from "@mantine/hooks";
import { useChargebeeConfig } from "~/lib/config-candidate";
import StepWrapper from "./Steps/StepWrapper";
// import StockAndCountdown from "./StockAndCountdown";

const steps = ["voucher", "contact", "notices", "payment"];

export default function LandingFormWizard({ isMobile }: { isMobile?: boolean }) {
  const fetcher = useFetcher();
  const {
    form,
    activeStep, setActiveStep,
    isPaymentComponentReady,
    setIsPaymentComponentReady,
    paymentSuccess, setPaymentSuccess,
    isPaymentFormValid, setIsPaymentFormValid,
    cart,
    isTokenizing,
    emailExists,
    setEmailExists,
    zipCodeAvailability,
    loadAllVoucherAvailability,
  } = useWizardFormContext();

  const paymentRef = useRef<any>(null);

  const { ref, inViewport } = useInViewport();
  useEffect(() => {
    if (inViewport) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [inViewport]);

  // Load Chargebee
  const [chargebee, setChargebee] = useState<any>(null);
  const chargebeeConfig = useChargebeeConfig();

  useEffect(() => {
    const loadChargebee = async () => {
      if (typeof window === 'undefined' || !window.Chargebee) {
        // console.error('Chargebee is not available.');
        return;
      }
      try {
        const instance = await window.Chargebee.init({
          site: chargebeeConfig.site,
          publishableKey: chargebeeConfig.publishableKey,
        });
        setChargebee(instance);
      } catch (error) {
        // console.error('Error initializing Chargebee:', error);
      }
    };

    // Check for existing script
    const existingScript = document.querySelector('script[src="https://js1.chargebee.com/v2/chargebee.js"]');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://js1.chargebee.com/v2/chargebee.js';
      script.async = true;
      script.onload = loadChargebee;
      script.onerror = () => console.error('Error loading Chargebee script.');
      document.body.appendChild(script);
    } else {
      loadChargebee();
    }
  }, []);

  // Next step logic
  async function handleNext() {
    const currentStep = steps[activeStep];
    const stepSchemaMap = {
      voucher: wizardFormSchema.pick({ voucher: true }),
      contact: wizardFormSchema.pick({ contactInfo: true }),
      notices: wizardFormSchema.pick({ readNotices: true }),
      payment: wizardFormSchema.pick({ paymentToken: true }),
    };

    const currentStepSchema = (stepSchemaMap as any)[currentStep];

    if (zipCodeAvailability === false && currentStep === 'contact') {
      try {
        await currentStepSchema.parseAsync(form.values);
        fetcher.submit(
          {
            _action: 'interested',
            data: JSON.stringify(form.values),
          },
          { method: 'post' }
        );
      } catch (err) {
        if (err instanceof z.ZodError) {
          err.errors.forEach((fieldError) => {
            form.setFieldError(fieldError.path.join('.'), fieldError.message);
          });
        }
        // console.error('Validation failed for step:', currentStep);
      }
      return;
    }

    try {
      await currentStepSchema.parseAsync(form.values);

      if (currentStep === 'voucher' || currentStep === 'notices') {
        setActiveStep((cur) => Math.min(cur + 1, steps.length - 1));
        return;
      }

      fetcher.submit(
        {
          _action: 'next',
          step: currentStep,
          csrf: csrfToken,
          data: JSON.stringify(form.values),
        },
        { method: 'post' }
      );
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.errors.forEach((fieldError) => {
          form.setFieldError(fieldError.path.join('.'), fieldError.message);
        });
      }
      // console.error('Validation failed for step:', currentStep);
    }
  }

  // Check for response, proceed on success
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data?.success) {
      setActiveStep((cur) => Math.min(cur + 1, steps.length - 1));

      // Sync updated form data from server
      if (fetcher.data.formData) {
        form.setValues(fetcher.data.formData);
      }
      setEmailExists(false);
    } else if (fetcher.data?.success === false) {
      // We have an error
      if (fetcher.data.error === 'existing_member' || fetcher.data.error === 'already_purchased') {
        form.setFieldError(
          'contactInfo.email',
          // 'An account with this email already exists. Please log in to continue.'
          true
        );
        setEmailExists(true);
      } else {
        // Fallback
        // console.log('Unhandled error:', fetcher.data.error);
      }
    }
  }, [fetcher.state, fetcher.data]);


  // Final submit logic
  const handleFinalSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!form.validate()) return false;

    if (!paymentRef.current) {
      // console.error('No paymentRef found.');
      return false;
    }

    setIsPaymentComponentReady(false);
    const isValid = await paymentRef.current.validatePaymentForm();
    setIsPaymentFormValid(isValid);
  };

  // Get CSRF token from loader data
  const { csrfToken } = useWizardFormContext();

  // On payment authorized, do final server submit
  useEffect(() => {
    if (isPaymentFormValid) {
      fetcher.submit(
        {
          _action: 'submit',
          csrf: csrfToken,
          data: JSON.stringify(form.values),
        },
        { method: 'post' }
      );
    }
  }, [isPaymentFormValid]);

  // Handle payment success
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data?.success && fetcher.data.action === 'submit') {
      // Payment or invoice creation succeeded
      // console.log('Payment success:', fetcher.data);

      // Store the customer/invoice data for future reference
      form.setFieldValue('customerId', fetcher.data.customerId);
      form.setFieldValue('invoiceId', fetcher.data.invoiceId);

      // Destroy payment form and replace with "Payment Successful" message
      setPaymentSuccess(true);
    }
  }, [fetcher.state, fetcher.data]);

  // Handle payment failure
  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data && !fetcher.data.success && fetcher.data.action === "submit") {
      // Payment or invoice creation failed
      // console.error("Payment failed:", fetcher.data.error);
      setIsPaymentComponentReady(true); // Re-enable the payment form and disable loading overlay
      setIsPaymentFormValid(false);

      // Store the partial customer/invoice data so we can retry next time
      // form.setFieldValue("customerId", fetcher.data.customerId);
      // form.setFieldValue("invoiceId", fetcher.data.invoiceId);
      // form.setFieldValue("paymentAttempt.failedAttempts", fetcher.data.failedAttempts);
      // update form data with formData from server
      if (fetcher.data.formData) form.setValues(fetcher.data.formData);

      // Show the error to the user
      // alert(fetcher.data.error || "Your payment could not be processed.");
      form.setFieldError("paymentToken", fetcher.data.error || "Your payment could not be processed.");
    }
  }, [fetcher.state, fetcher.data]);

  function renderStep(stepName: string) {
    switch (stepName) {
      case "voucher":
        return (
          <StepWrapper>
            <StepSelectVoucher />
          </StepWrapper>
        );
      case "contact":
        return (
          <StepWrapper>
            <StepContactInfo />
          </StepWrapper>
        );
      case "notices":
        return (
          <StepWrapper>
            <StepNotices />
          </StepWrapper>
        );
      case "payment":
        return (
          <StepWrapper>
            <StepPaymentCustom
              ref={paymentRef}
              chargebee={chargebee}
              onPaymentComponentReady={setIsPaymentComponentReady}
            />
          </StepWrapper>
        );
      default:
        return null;
    }
  }

  const selectedProduct = cart[form.values.voucher];

  const getFormHeader = () => {

    if (activeStep <= 1) {
      return (
        <Stack gap="sm">
          <Paper p="sm" bg="green" radius="md">
            <Text ta="center" fw={600} fz="sm" c="white">
              Don't miss out—grab your discount today!<br />
              100% refundable if unused.
            </Text>
          </Paper>
          {/* <Text ta="center" fz="sm">
            Only {voucherAvailability?.available ? voucherAvailability?.available.toString() : "10"} Discount
            Vouchers Remaining
          </Text> */}
          {/* <StockAndCountdown /> */}
        </Stack>
      );
    }

    if (paymentSuccess) {
      return;
    }

    return (
      <Stack gap="sm">
        <Paper p="sm" bg="green" radius="md">
          <Text ta="center" fw={600} fz="sm" c="white">
            🎉 Congrats! You've reserved your discount for...
          </Text>
        </Paper>
        <SelectedProduct inline />
        <Paper p="xs">
          <Text ta="center" fz="sm">
            We'll hold it for you for <CountdownTimer />.
          </Text>
        </Paper>
      </Stack>
    );
  }

  const canContinue = () => {
    if (zipCodeAvailability === false || fetcher.state !== 'idle') {
      return false;
    }
    return true;
  }

  const getFormContinueButton = () => {
    let buttonText = 'Continue';
    switch (activeStep) {
      case 0:
        buttonText = 'Get a Cleaning!';
        break;
      case 1:
        buttonText = 'Lock in Your Discount!';
        break;
      case 2:
        buttonText = 'Continue';
        break;
      default:
        break;
    }
    return (
      <Button onClick={handleNext} fullWidth size="lg" radius="md" loading={fetcher.state !== 'idle'}>
        {activeStep === 1 && zipCodeAvailability === false ? 'Request Now' : buttonText}
      </Button>
    );
  }

  const getFormButtons = () => {

    if (paymentSuccess) {
      return;
    }

    return (
      <Box>
        {activeStep === steps.length - 1 ? (
          <Form action="?index" method="post" onSubmit={handleFinalSubmit} style={{ width: '100%' }}>
            <input
              type="hidden"
              name="csrf"
              value={csrfToken}
            />
            <input
              type="hidden"
              name="data"
              value={JSON.stringify(form.values)}
            />
            <Button type="submit" name="_action" value="submit" fullWidth size="lg" radius="md" loading={fetcher.state !== 'idle' || isTokenizing}>
              Pay ${selectedProduct?.price} & Schedule
            </Button>
          </Form>
        ) : (
          getFormContinueButton()
        )}
      </Box>
    );
  }

  // ─────────────────────────────────────────────────────────────
  //  Desktop (inline) layout
  //  - Show all steps inline
  //  Mobile Layout
  //  - Step 1 inline with pinned bottom bar
  //  - Steps 2+ in a full-screen overlay with pinned bottom bar
  // ─────────────────────────────────────────────────────────────

  return (
    <Container
      className="sticky-container"
      p={0}
      pos={{ base: 'relative', md: 'sticky' }}
    >

      {getFormHeader()}

      <Stepper
        active={activeStep}
        size="xs"
        mt={0}
        mb="md"
      >
        {steps.map((step, index) => (
          <Stepper.Step
            key={step}
            label={step.toUpperCase()}
            description={`Step ${index + 1}`}
          >
            {activeStep === index && renderStep(step)}
          </Stepper.Step>
        ))}
      </Stepper>

      {/* Form Buttons and Selected Product*/}
      <Box pos={{ base: "fixed", md: "relative" }} className="form-bottom-bar">
        <Box style={{ width: '100%' }}>
          {getFormButtons()}
        </Box>

        <Box className="selected-product-box">
          {!paymentSuccess && (
            <SelectedProduct />
          )}
        </Box>

      </Box>

      <SurveyModal
        isOpen={Boolean(fetcher.data?.success && fetcher.data?.email && fetcher.data?.orderId && fetcher.data?.customerId && fetcher.data?.purchaseAmount)}
        email={fetcher.data?.email}
        orderId={fetcher.data?.orderId}
        customerId={fetcher.data?.customerId}
        userId={fetcher.data?.userId}
        purchaseAmount={fetcher.data?.purchaseAmount}
        csrfToken={csrfToken}
      />

    </Container>
  );
}