import { Button, Stack, Text, Title } from "@mantine/core";
import { useWizardFormContext } from "../form/wizard-form-context";
import TextInputMaterial from "../form/inputs/TextInputMaterial";
import ContactInfoInputs from "../form/Steps/StepContactInfo/ContactInfoInputs";
import { useFetcher } from "@remix-run/react";
import { z } from "zod";
import { wizardFormSchema } from "../form/validation";
import OptInTermsCheckbox from "../form/Steps/StepContactInfo/OptInTermsCheckbox";
import { config } from "../../lib/config-candidate";

export default function ZipNotCovered() {
  const { form, setZipCodeAvailability, csrfToken } = useWizardFormContext();
  const { getInputProps } = form;
  const fetcher = useFetcher();

  const handleRegisterInterest = async () => {
    // Validate only the fields required for registration of interest
    const stepSchema = wizardFormSchema.pick({ contactInfo: true }).pick({
      contactInfo: true,
    });

    try {
      await stepSchema.parseAsync(form.values);
      // Track to index with interested action
      fetcher.submit(
        {
          _action: 'interested',
          csrf: csrfToken,
          data: JSON.stringify(form.values),
        },
        { method: 'post' }
      );
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.errors.forEach((fieldError) => {
          form.setFieldError(fieldError.path.join('.'), fieldError.message);
        });
      }
      // console.error('Validation failed for step:', err);
    }
  };

  return (
    <Stack gap="sm" justify="flex-start">
      <Title order={2} size="h3" fz={{ base: "h4", md: "h3" }} ta="center">Coming Soon!</Title>
      <Text mb="sm" size="sm" ta="center">
        {config.zipCodeServiceArea.notAllowedMessage}
      </Text>
      <ContactInfoInputs />
      <TextInputMaterial
        label="ZIP Code"
        placeholder="21211"
        required
        readOnly
        disabled
        autoComplete="postal-code"
        {...getInputProps('zipCodeRequest')}
        value={form.values.contactInfo.zipCode}
      />
      <OptInTermsCheckbox />
      <Button size="lg" radius="md" onClick={handleRegisterInterest}>Request Now</Button>
      <Button size="compact-sm" color="gray" bd="none" td="underline" variant="outline" onClick={() => setZipCodeAvailability(null)}>Check another zip code</Button>
    </Stack>
  )
}