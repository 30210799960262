import React, { useEffect, useState } from "react";
import { Text } from "@mantine/core";

interface CountdownTimerProps {
  /** Starting countdown minutes. Defaults to 10 (10:00). */
  initialMinutes?: number;
}

/**
 * A countdown timer that starts at `initialMinutes` (default: 10),
 * then resets to the initial value once it reaches zero.
 * Displays time in MM:SS format.
 */
export function CountdownTimer({ initialMinutes = 10 }: CountdownTimerProps) {
  const [timeRemaining, setTimeRemaining] = useState(initialMinutes * 60);

  useEffect(() => {
    // @todo: Update to use Mantine useInterval hook: https://mantine.dev/hooks/use-interval/
    const timerId = setInterval(() => {
      setTimeRemaining((prev) => {
        // If we reach or drop below 1 second, reset to initial
        if (prev <= 1) {
          return initialMinutes * 60;
        } else {
          return prev - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [initialMinutes]);

  // Convert total seconds to minutes and seconds
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  const formatted = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return (
    <Text span inherit>
      {formatted}
    </Text>
  );
}