import { useEffect } from 'react';
import { Modal, Stack, Text, Select, TextInput, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';

interface SurveyModalProps {
  isOpen: boolean;
  onClose?: () => void;
  email?: string;
  orderId?: string;
  customerId?: string;
  userId?: string;
  purchaseAmount?: string;
  csrfToken: string;
}

export default function SurveyModal({
  isOpen,
  onClose,
  email,
  orderId,
  customerId,
  userId,
  purchaseAmount,
  csrfToken
}: SurveyModalProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const isSubmitting = false;

  const form = useForm({
    initialValues: {
      heardAboutUs: '',
      otherFeedback: '',
      email: email || '',
    },
    validate: {
      heardAboutUs: (value) => value.trim().length === 0 ? 'Please select how you heard about us' : null,
      otherFeedback: (value, values) =>
        values.heardAboutUs === 'other' && value.trim().length === 0
          ? 'Please describe how you heard about us'
          : null,
    },
  });

  useEffect(() => {
    if (isOpen) {
      open();
    }
  }, [isOpen, open]);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        close();
        onClose?.();
      }}
      withCloseButton={false}
      closeOnClickOutside={false}
      title={<Text fz="h3" fw={600}>Order Confirmed! Thank You.</Text>}
      centered
    >
      <Stack gap="md">
        <Text size="sm">
          Before you proceed to schedule, please tell us how you heard about us.
        </Text>

        <form action="/survey" method="post">
          <Stack gap="md">
            <Select
              size="lg"
              label="How did you hear about us?"
              placeholder="Select an option"
              data={[
                { value: 'google', label: 'Google' },
                { value: 'facebook', label: 'Facebook' },
                { value: 'instagram', label: 'Instagram' },
                { value: 'friend', label: 'Friend' },
                { value: 'other', label: 'Other' },
              ]}
              {...form.getInputProps('heardAboutUs')}
              name="heardAboutUs"
              required
            />

            {form.values.heardAboutUs === 'other' && (
              <TextInput
                size="lg"
                label="Please specify"
                placeholder="Type here..."
                {...form.getInputProps('otherFeedback')}
                name="otherFeedback"
                required
              />
            )}

            <input type="hidden" name="csrf" value={csrfToken} />

            <Button
              type="submit"
              style={{
                cursor: 'pointer'
              }}
              color='green'
              fullWidth
              radius="md"
              size='lg'
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Submit & Continue
            </Button>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
}