import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  memo,
} from 'react';
import {
  Stack,
  Text,
  Group,
  Radio,
  Title,
  Badge,
  Box,
  Progress,
  Skeleton,
  Button,
  TextInput,
  ActionIcon,
} from '@mantine/core';
import { useFetcher } from '@remix-run/react';
import { useWizardFormContext } from '../wizard-form-context';
import { config } from '../../../lib/config-candidate';
import OptInTermsText from './StepContactInfo/OptInTermsText';
import { useClickOutside } from '@mantine/hooks';

// Availability interface
export interface VoucherAvailability {
  available: number;
  maxRedemptions: number;
  redemptions: number;
}

// Voucher definition
export interface VoucherOption {
  name: string;
  description: string;
  value: string;
  price: string;
  originalPrice: string;
  discountPercentage: string;
  default: boolean;
  mostPopular: boolean;
}

/** 
 * Single “Notify me of next drop” button. 
 * Renders a success message if `notifyNextDropRequested` is already true.
 */
const NotifyMeButton = memo(function NotifyMeButton({
  voucherId,
}: {
  voucherId: string;
}) {
  const { form, csrfToken } = useWizardFormContext();
  const notificationRequestFetcher = useFetcher<{ success?: boolean; notifyNextDropRequested?: boolean }>();
  const [showEmailPrompt, setShowEmailPrompt] = useState(false);
  const [optimisticRequested, setOptimisticRequested] = useState(false);

  // If user has already requested to be notified globally:
  const alreadyRequested = form.values.notifyNextDropRequested === true || optimisticRequested;

  // Closes the email prompt form if they click outside of it
  const clickOutsideRef = useClickOutside(() => setShowEmailPrompt(false));

  // If they click "Notify me" and we have an email, submit the request
  const handleNotifyMe = useCallback(() => {
    if (alreadyRequested) return;

    const storedEmail = form.values.contactInfo?.email;
    if (storedEmail) {
      notificationRequestFetcher.submit(
        {
          _action: 'stockNotification',
          voucherId,
          csrf: csrfToken,
          data: JSON.stringify(form.values),
        },
        { method: 'post' },
      );
    } else {
      setShowEmailPrompt(true);
    }
  }, [alreadyRequested]);

  // If they need to type an email:
  const handleNotifyMeWithEmail = useCallback(() => {

    form.validateField('contactInfo.email');
    const isValid = form.isValid('contactInfo.email');
    if (!isValid) {
      return;
    }

    // Optimistic UI update
    setOptimisticRequested(true);
    form.setFieldValue('notifyNextDropRequested', true);
    notificationRequestFetcher.submit(
      {
        _action: 'stockNotification',
        voucherId,
        csrf: csrfToken,
        data: JSON.stringify(form.values),
      },
      { method: 'post' },
    );
    setShowEmailPrompt(false);
  }, [form.values]);


  useEffect(() => {
    if (notificationRequestFetcher.state === 'idle') {
      if (notificationRequestFetcher.data?.success && notificationRequestFetcher.data.notifyNextDropRequested) {
        // Server confirmed, so ensure it is reflected to the main form
        form.setFieldValue('notifyNextDropRequested', true);
        setOptimisticRequested(false); // clear out our local state

      } else if (notificationRequestFetcher.data && !notificationRequestFetcher.data.success) {
        // Request failed: revert the optimistic update
        form.setFieldValue('notifyNextDropRequested', false);
        setOptimisticRequested(false);
      }
    }
  }, [notificationRequestFetcher.state, notificationRequestFetcher.data]);

  const isSubmitting = notificationRequestFetcher.state === 'submitting';
  const submitFailed =
    notificationRequestFetcher.state === 'idle' && notificationRequestFetcher.data && !notificationRequestFetcher.data.success;

  if (alreadyRequested) {
    return (
      <Text size="xs" c="green" fw="bold" data-testid="already-requested">
        ✅ We'll notify you as soon as new vouchers drop!
      </Text>
    );
  }

  return (
    <Box ref={clickOutsideRef}>
      <Text size="xs" c="dimmed" span data-testid="sold-out-text">
        🚫 Sold out
      </Text>{' '}
      <Button
        size="compact-xs"
        variant="light"
        color="red"
        fw="normal"
        radius="sm"
        style={{ cursor: 'pointer' }}
        onClick={handleNotifyMe}
        loading={isSubmitting}
        data-testid="notify-me-button"
      >
        Notify me when it's back
      </Button>

      {showEmailPrompt && (
        <Stack mt="sm" gap="xs">
          <Text size="xs" c="dimmed">
            Enter your email, and we'll let you know as soon as we release new vouchers:
          </Text>
          <TextInput
            {...form.getInputProps('contactInfo.email')}
            placeholder="you@example.com"
            autoComplete="email"
            type="email"
            size="md"
            radius="md"
            data-testid="notify-me-email-input"
            rightSection={
              <ActionIcon
                radius="md"
                w={60}
                color="green"
                variant="filled"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNotifyMeWithEmail();
                }}
                loading={isSubmitting}
                data-testid="submit-notify-me-button"
              >
                <Text fz="xs" fw="bold">Submit</Text>
              </ActionIcon>
            }
            rightSectionWidth={80}
            required
          />
          {form.errors.contactInfo?.email && (
            <Text c="red" size="xs" mt="xs" data-testid="email-validation-error">
              {form.errors.contactInfo.email}
            </Text>
          )}
          <OptInTermsText />
        </Stack>
      )}

      {submitFailed && (
        <Text c="red" size="xs" mt="xs">
          Couldn't save your request. Please try again.
        </Text>
      )}
    </Box>
  );
});

/** 
 * A single voucher card that checks availability. 
 * If sold out, it shows <NotifyMeButton/>.
 */
const VoucherCard = memo(function VoucherCard({
  option,
  isSelected,
  onChange,
  showNotifyMeButton = true,
}: {
  option: VoucherOption;
  isSelected: boolean;
  onChange: (val: string) => void;
  showNotifyMeButton?: boolean;
}) {
  const { voucherAvailability } = useWizardFormContext();
  const availability = voucherAvailability[option.value];
  let progressValue = 0;
  let isSoldOut = false;
  let displayedElement: React.ReactNode = null;

  if (availability) {
    const { available, maxRedemptions, redemptions } = availability;
    progressValue = maxRedemptions ? (redemptions / maxRedemptions) * 100 : 0;
    isSoldOut = progressValue >= 100;

    // let availableText = null;
    // if (progressValue >= 100) {
    //   availableText = <Text size="xs" c="red" span lh="xs">Sold out - check back soon!</Text>;
    // } else if (progressValue <= 10) {
    //   availableText = <Text size="xs" c="dimmed" span lh="xs">Freshly stocked!</Text>;
    // } else if (progressValue > 50) {
    //   availableText = <Text size="xs" c="dimmed" span lh="xs">⚡️ Hurry, almost gone!</Text>;
    // } else {
    //   availableText = <Text size="xs" c="dimmed" span lh="xs">Available now</Text>;
    // }

    if (isSoldOut) {
      if (showNotifyMeButton) {
        displayedElement = <NotifyMeButton voucherId={option.value} />;
      } else {
        displayedElement = (
          <Text size="xs" c="dimmed" span data-testid="sold-out-text">
            🚫 Sold out
          </Text>
        );
      }
    } else if (progressValue >= config.content.vouchers.stockIndicators.lowStockThresholdPercentage) {
      displayedElement = (
        <Text size="xs" c="red" fw="bold" data-testid="low-stock-indicator">
          ⚡️ Hurry - only {available} left
        </Text>
      );
    } 
    // else {
    //   displayedElement = (
    //     <Text size="xs" c="dimmed" span data-testid="available-text">
    //       {available} remaining
    //     </Text>
    //   );
    // }
  }

  return (
    <Box style={{ position: 'relative' }}>
      {option.mostPopular && (
        <Badge
          color="green"
          variant="filled"
          size="xs"
          style={{ position: 'absolute', top: -7, left: 18, zIndex: 2 }}
          data-testid="most-popular-badge"
        >
          Featured
        </Badge>
      )}

      <Radio.Card
        component="div"
        value={option.value}
        data-testid={`voucher-card-${option.value}`}
        style={{
          padding: '16px',
          border: isSelected
            ? '2px solid var(--mantine-primary-color-filled)'
            : isSoldOut
              ? '2px solid var(--mantine-color-gray-1)'
              : '2px solid var(--mantine-color-default-border)',
          cursor: isSoldOut ? 'default' : 'pointer',
          backgroundColor: isSoldOut ? 'var(--mantine-color-gray-0)' : 'transparent',
        }}
        radius="md"
        mb="sm"
        disabled={isSoldOut}
        onClick={() => {
          if (!isSoldOut) onChange(option.value);
        }}
      >
        <Group justify="space-between" align="flex-start" wrap='nowrap'>
          <Stack gap="xs">
            <Text fz="sm" fw={600}>
              {option.name}
            </Text>
            <Text fz="xs" fw="light" c="dimmed">
              {option.description}
            </Text>
          </Stack>

          <Stack gap="xs" align="flex-end">
            <Group gap="xs">
              <Text size="xs" c="dimmed" td="line-through" data-testid={`voucher-original-price-${option.value}`}>
                ${option.originalPrice}
              </Text>
              <Text fz="sm" fw={600} data-testid={`voucher-price-${option.value}`}>
                ${option.price}
              </Text>
            </Group>
            <Text fz="xs" fw={600} data-testid={`voucher-discount-${option.value}`}>
              {option.discountPercentage}% off
            </Text>
          </Stack>
        </Group>

        <Box mt="xs">
          {availability ? (
            <>
              {!isSoldOut && (
                <Progress.Root size={4} mt={4} mb={4} w={175}>
                  <Progress.Section
                    value={100 - progressValue}
                    color={progressValue >= config.content.vouchers.stockIndicators.lowStockThresholdPercentage ? 'red' : 'green'}
                  />
                </Progress.Root>
              )}
              {displayedElement}
            </>
          ) : (
            <Box w={175}>
              <Skeleton height={4} mb="sm" data-testid="availability-skeleton" />
            </Box>
          )}
        </Box>
      </Radio.Card>
    </Box>
  );
});

function StepSelectVoucher({ withHeader = true }) {
  const { form, setCart, voucherAvailability, loadAllVoucherAvailability, hasLoadedAvailability } = useWizardFormContext();

  // Build array of voucher data
  const allVouchers = useMemo(() => {
    return (form.values.products ?? []).map((p) => ({
      name: p.title,
      description: p.description,
      value: p.id.toString(),
      price: p.price,
      originalPrice: p.originalPrice,
      discountPercentage: p.discountPercentage,
      default: p.default || false,
      mostPopular: p.mostPopular,
    }));
  }, [form.values.products]);

  // On user radio selection
  const handleVoucherChange = useCallback(
    (value: string) => {
      const a = voucherAvailability[value];
      if (a && a.redemptions < a.maxRedemptions) {
        form.setFieldValue('voucher', value);
        const sel = form.values.products.find(
          (prod) => prod.id.toString() === value,
        );
        if (sel) setCart({ [sel.id]: sel });
      }
    },
    [voucherAvailability, form.values.voucher, form.values.products, setCart],
  );

  // Handle initial voucher selection with availability data
  useEffect(() => {
    if (!form.values.voucher && form.values.products && Object.keys(voucherAvailability).length > 0) {
      const current = form.values.voucher;
      if (current) {
        const curAvail = voucherAvailability[current];
        if (curAvail && curAvail.redemptions < curAvail.maxRedemptions) return;
      }
      // pick a fallback
      const inStock = form.values.products.filter((p) => {
        const a = voucherAvailability[p.id.toString()];
        return a && a.redemptions < a.maxRedemptions;
      });
      let chosen =
        inStock.find((p) => p.default) ||
        inStock.sort((a, b) => +a.price - +b.price)[0];
      if (chosen) handleVoucherChange(chosen.id.toString());
    }
  }, [form.values.products, voucherAvailability, handleVoucherChange]);
  useEffect(() => {
    // console.log('Checking if voucher availability needs to be loaded...');
    if (!hasLoadedAvailability) {
      // console.log('Loading voucher availability...');
      loadAllVoucherAvailability();
    }
  }, [hasLoadedAvailability, loadAllVoucherAvailability]);
  return (
    <div>
      {withHeader && (
        <Box mt="md">
          <Title order={2} fz="h4" mb="sm" ta="center" data-testid="voucher-selection-header">
            Claim Your Discount Voucher
          </Title>
          <Text fz="sm" mb="md" c="dimmed" ta="center" data-testid="voucher-selection-subheader">
            Choose Two, Three, Four, or Six Hours of Cleaning from {config.brand.shortName}
          </Text>
        </Box>
      )}

      <Radio.Group
        value={form.values.voucher}
        onChange={handleVoucherChange}
        required
        aria-label="Select one of our special offers"
      >
        <Stack gap="xs">
          {allVouchers.map((v) => (
            <VoucherCard
              key={v.value}
              option={v}
              isSelected={form.values.voucher === v.value}
              onChange={handleVoucherChange}
              showNotifyMeButton={withHeader}
            />
          ))}
        </Stack>
      </Radio.Group>
    </div>
  );
}

export default StepSelectVoucher;