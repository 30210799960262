import { Avatar, Box, Button, Group, Rating, Stack, Text } from "@mantine/core";
import { Review } from "../Reviews";

type ReviewListProps = {
  list: Review[];
  currentPage: number;
  nextPage: number | null;
  prevPage: number | null;
  pageTotal: number;
  onPageChange: (page: number) => void;
  withButtons?: boolean;
  layout?: "contained" | "inlined";
};

export default function ReviewList({
  list,
  currentPage,
  nextPage,
  prevPage,
  pageTotal,
  onPageChange,
  withButtons = true,
  layout = "inlined",
}: ReviewListProps) {
  const initials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  return (
    <Box>
      {/* Scrollable Review List */}
      <Stack gap="md" style={{ flex: 1, overflowY: "auto" }} mah={layout === "contained" ? { base: "auto", sm: "50vh" } : "auto"} pb={layout === "contained" ? { base: 60, sm: 16 } : 16} data-testid="review-list-stack">
        {list.map((review) => (
          <Stack key={review.id} pb="md">
            <Group gap="sm">
              <Avatar color="gray" radius="xl">{initials(review.customer_first_name.toUpperCase(), review.customer_last_name.toUpperCase())}</Avatar>
              <div>
                <Text fw={500}>{review.customer_first_name.charAt(0).toUpperCase() + review.customer_first_name.slice(1).toLowerCase()} {review.customer_last_name.charAt(0).toUpperCase()}.</Text>
                <Text fz="xs" c="dimmed">{review.city}</Text>
              </div>
            </Group>
            <Group gap="xs" align="center">
              <Rating value={review.rating} size="sm" readOnly />{" "}
              <Text fz="xs" c="dimmed" span>{new Date(review.booking_date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}</Text>
            </Group>
            <Text fz="sm">{review.review}</Text>
          </Stack>
        ))}

      </Stack>

      {/* Fixed Bottom Navigation */}
      {withButtons && (
        <Box
          pos={{ base: "fixed", sm: "static" }}
          pt="md"
          pb={{ base: "md", sm: 0 }}
          px={{ base: "md", sm: 0 }}
          style={{
            // position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            // padding: "12px 16px",
            borderTop: "1px solid var(--mantine-color-default-border)",
            zIndex: 1000, // Ensure it's above other content
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            disabled={!prevPage}
            onClick={() => prevPage && onPageChange(prevPage)}
            data-testid="review-list-previous-button"
          >
            Previous
          </Button>
          <Button
            disabled={!nextPage}
            onClick={() => nextPage && onPageChange(nextPage)}
            data-testid="review-list-next-button"
          >
            Next
          </Button>
        </Box>
      )}

    </Box>
  );
}
