import { useState } from "react";
import { Box, TextInput, TextInputProps } from "@mantine/core";
import styles from "./TextInputMaterial.module.css";

interface TextInputMaterialProps extends TextInputProps {
  label: string;
}

export default function TextInputMaterial({ label, ...props }: TextInputMaterialProps) {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => setFocused(true);
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <Box
      className={`${styles.inputContainer} ${focused ? styles.focused : ""}`}
    >
      <TextInput
        {...props}
        classNames={{ input: styles.input }}
        size="lg"
        labelProps={{ fz: 'sm' }}
        radius="md"
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label
        className={`${styles.label} ${focused || props.value ? styles.shrink : ""}`}
      >
        {label}
      </label>
    </Box>
  );
}
