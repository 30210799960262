import { useState } from "react";
import { Text, Grid, Group, Button, Popover, Paper } from "@mantine/core";
import StepSelectVoucher from "./Steps/StepSelectVoucher";
import { useWizardFormContext } from "./wizard-form-context";

function SelectedProduct({ inline }: { inline?: boolean }) {
  const [opened, setOpened] = useState(false);
  const { form, cart, activeStep } = useWizardFormContext();

  const selectedProduct = cart[form.values.voucher];

  if (inline === undefined) {
    inline = false;
  }

  // if (activeStep === 0) {
  //   return null;
  // }

  if (!selectedProduct) {
    return null;
  }


  if (inline) {
    return (
      <Paper p="xs" bg="var(--mantine-color-gray-1)" radius="md">
        <Text fz="sm" ta="center" fw={600}>
          {selectedProduct?.title} for ${selectedProduct?.price} - {selectedProduct?.discountPercentage}% off!
        </Text>
      </Paper>
    );
  }

  return (
    <Paper p={0} mt={{ sm: 0, md: "md" }} mb="md">
      <Text fz="xs">
        You're Getting
      </Text>
      <Grid>
        <Grid.Col span={9}>
          <Group gap="xs">
            <Text fw={600}>
              {selectedProduct?.title}
            </Text>

            {/* Popover Trigger */}
            <Popover
              position="bottom"
              withArrow
              opened={opened}
              onChange={setOpened}
              shadow="md"
              closeOnClickOutside
            >
              <Popover.Target>
                <Button
                  size="compact-xs"
                  variant="outline"
                  color="gray"
                  onClick={() => setOpened((o) => !o)}
                >
                  Edit
                </Button>
              </Popover.Target>

              {/* Popover Content */}
              <Popover.Dropdown p="md">
                <StepSelectVoucher withHeader={false} />
              </Popover.Dropdown>
            </Popover>
          </Group>
        </Grid.Col>

        <Grid.Col span={3}>
          {/* <Group justify="flex-end" gap="xs" > */}
          <Text fz="lg" ta="end"><Text span fw={300} c="dimmed" ta="left" td={"line-through"}>${selectedProduct?.originalPrice}</Text>&nbsp;<Text span fw={600} ta="right">${selectedProduct?.price}</Text></Text>
          {/* </Group> */}
        </Grid.Col>
      </Grid>
    </Paper>
  );
}

export default SelectedProduct;