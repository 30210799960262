import { Box, Center, Group, Paper, Stack, Text, ThemeIcon, Title, useMantineTheme } from "@mantine/core";
import { config } from '../../lib/config-candidate';
import React from 'react';


export default function MembershipBenefits() {

  return (
    <Box>
      <Paper p="lg" shadow="sm" radius="md" withBorder>
        <Title order={2} size="h4" fz={{ base: "lg", md: "xl" }} ta="center">
          {config.pricingAndPromotion.membership.name} saves you $<Text span inherit data-testid="savings-amount">{(config.pricingAndPromotion.standardRate.amount - config.pricingAndPromotion.discountedRate.amount).toString()}</Text> per hour off all your future cleanings
        </Title>

        {/* Horizontal layout for medium+ screens */}
        <Group gap="sm" p="sm" justify="space-around" grow visibleFrom="md">
          <Text fz="md" ta="center">
            $<Text span inherit data-testid="standard-rate">{config.pricingAndPromotion.standardRate.amount.toString()}</Text>/hr<br />Standard Hourly Rate
          </Text>
          <Center>
            <ThemeIcon color="gray.0" autoContrast variant="filled" size="xl" radius="xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l14 0" />
                <path d="M13 18l6 -6" />
                <path d="M13 6l6 6" />
              </svg>
            </ThemeIcon>
          </Center>
          <Text fz="md" ta="center">
            $<Text span inherit data-testid="discounted-rate">{config.pricingAndPromotion.discountedRate.amount.toString()}</Text>/hr<br />{config.pricingAndPromotion.membership.name} Member Rate
          </Text>
        </Group>

        {/* Stacked layout for small screens */}
        <Stack hiddenFrom="md" align="center" gap="sm">
          <Text fz="md" ta="center">
            $<Text span inherit data-testid="standard-rate">{config.pricingAndPromotion.standardRate.amount.toString()}</Text>/hr<br />Standard Hourly Rate
          </Text>
          <ThemeIcon color="gray.0" autoContrast variant="filled" size="xl" radius="xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l14 0" />
              <path d="M13 18l6 -6" />
              <path d="M13 6l6 6" />
            </svg>
          </ThemeIcon>
          <Text fz="md" ta="center">
            $<Text span inherit data-testid="discounted-rate">{config.pricingAndPromotion.discountedRate.amount.toString()}</Text>/hr<br />{config.pricingAndPromotion.membership.name} Member Rate
          </Text>
        </Stack>

        {/* Equal height Paper components */}
        <Group gap="lg" grow mt="md" direction={{ base: "column", md: "row" }} align="stretch">
          <Paper
            p="xl"
            radius="md"
            bg="gray.0"
            shadow="none"
            style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", textAlign: "center" }}
          >
            <Center mb="md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-pig-money"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M15 11v.01" />
                <path d="M5.173 8.378a3 3 0 1 1 4.656 -1.377" />
                <path d="M16 4v3.803a6.019 6.019 0 0 1 2.658 3.197h1.341a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-1.342c-.336 .95 -.907 1.8 -1.658 2.473v2.027a1.5 1.5 0 0 1 -3 0v-.583a6.04 6.04 0 0 1 -1 .083h-4a6.04 6.04 0 0 1 -1 -.083v.583a1.5 1.5 0 0 1 -3 0v-2l0 -.027a6 6 0 0 1 4 -10.473h2.5l4.5 -3h0z" />
              </svg>
            </Center>
            <Text fz="lg" fw={600}>
              Unbeatable Savings
            </Text>
            <Text fz="sm">
              For only $<Text span inherit data-testid="membership-rate">{config.pricingAndPromotion.membership.monthlyRate.amount.toString()}</Text>/month, book unlimited cleanings at $<Text span inherit data-testid="discounted-rate">{config.pricingAndPromotion.discountedRate.amount.toString()}</Text>/hr, all backed by our Satisfaction Guarantee.
            </Text>
          </Paper>
          <Paper
            p="xl"
            radius="md"
            bg="gray.0"
            shadow="none"
            style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", textAlign: "center" }}
          >
            <Center mb="md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-heart"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
              </svg>
            </Center>
            <Text fz="lg" fw={600}>
              Fair Pay for Quality Work
            </Text>
            <Text fz="sm">
              Your cleaner earns 100% of the cleaning fee ($<Text span inherit data-testid="discounted-rate">{config.pricingAndPromotion.discountedRate.amount.toString()}</Text>/hr).
            </Text>
          </Paper>
        </Group>
      </Paper>
    </Box>
  );
}