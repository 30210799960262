import { Modal, Stack, Text } from "@mantine/core";
import ReviewList from "./List";
import { ReviewListProps } from "../Reviews";
import { useEffect, useState } from "react";
import { useFetcher } from "@remix-run/react";
import ModalBase from "../../../components/ModalBase";
import ReviewRatingDisplay from "./ReviewRatingDisplay";
import { useWizardFormContext } from "~/components/form/wizard-form-context";

type ReviewsModalProps = {
  opened: boolean;
  setOpened: (open: boolean) => void;
};

/** ✅ Error Boundary for ReviewsModal **/
export function ErrorBoundary({ error }: { error: Error }) {
  return (
    <Stack gap="xs">
      <Text fz="sm" ta="center">
        ⚠️ Sorry, we couldn't load additional reviews at the moment. Please try again later.
      </Text>
    </Stack>
  );
}

export default function ReviewsModal({ opened, setOpened }: ReviewsModalProps) {

  const [reviewsModalData, setReviewsModalData] = useState<ReviewListProps | null>(null);
  const [currentPage, setCurrentPage] = useState(2);

  const { reviewsRating, reviewsCount } = useWizardFormContext();

  const fetcher = useFetcher();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Only fetch if modal is open and we need new data
    if (opened && (!reviewsModalData || reviewsModalData.curPage !== currentPage)) {
      fetcher.load(`/reviews?page=${currentPage}`);
    }
  }, [currentPage, reviewsModalData, opened]);

  // Reset modal data when closed
  // useEffect(() => {
  //   if (!opened) {
  //     setReviewsModalData(null);
  //     setCurrentPage(2);
  //   }
  // }, [opened]);

  useEffect(() => {
    if (fetcher.data && !fetcher.data.error) {
      setReviewsModalData(fetcher.data as ReviewListProps);
    }
  }, [fetcher.data]);

  // Handle fetch error state
  if (fetcher.data?.error) {
    return (
      <ModalBase opened={opened} setOpened={setOpened} title="Customer Reviews">
        <ErrorBoundary error={new Error('Failed to fetch reviews')} />
      </ModalBase>
    );
  }

  return (
    <ModalBase
      opened={opened}
      setOpened={setOpened}
      title="Customer Reviews"
      header={
        <>
          <Text fw="bold" fz="lg">Customer Reviews</Text>
          <ReviewRatingDisplay reviewsRating={reviewsRating} reviewsCount={reviewsCount} mb="md" align="center" />
        </>
      }
      data-testid="reviews-modal"
    >
      {reviewsModalData && (
        <Stack gap="sm">
          {/* <ReviewRatingDisplay reviewsRating={reviewsModalData.ratings.average} reviewsCount={reviewsModalData.ratings.count} mb="md" align="center" /> */}
          <ReviewList
            list={reviewsModalData.items}
            currentPage={currentPage}
            nextPage={reviewsModalData.nextPage}
            prevPage={reviewsModalData.prevPage}
            pageTotal={reviewsModalData.pageTotal}
            onPageChange={handlePageChange}
            layout="contained"
          />
        </Stack>
      )}
    </ModalBase>
  );
}