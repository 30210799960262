import { Alert } from "@mantine/core";
import { config } from "../../lib/config-candidate";

export default function ZipNotCoveredNotice() {

  return (
    <Alert color="red" title="Coming Soon!" radius="md" variant="light">
      {config.zipCodeServiceArea.notAllowedMessage}
    </Alert>
  )
}