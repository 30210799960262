import React from "react";
import { Box, Text, Group } from "@mantine/core";
import { config } from "~/lib/config-candidate";
import { useWizardFormContext } from "../wizard-form-context";

interface WizardStepsProgressProps {

  /** Whether the component is being displayed on a mobile device */
  isMobile: boolean | undefined;

  /** Whether to display step numbers (1, 2, 3, ...) */
  withNumbers?: boolean;

  /** Props to pass to the root Box component */
  [x: string]: any;
}

/**
 * Displays a basic horizontal progress bar based on how many steps have been completed,
 * along with step labels below the bar.
 */
export function WizardStepsProgress({
  isMobile,
  withNumbers = true,
  ...props
}: WizardStepsProgressProps) {
  const { activeStep } = useWizardFormContext();

  // Import steps from config and add a "schedule" step at the end of the list
  let steps = [
    ...config.content.form.steps,
    "schedule",
  ]

  // Set custom step labels by index
  steps = steps.map((step, index) => {
    // if (index === 0) return !isMobile ? "Select Discount Voucher" : "Select";
    if (index === 0) return "Voucher";
    if (index === 1) return "Contact";
    if (index === 2) return "Review";
    if (index === 3) return "Checkout";
    if (index === 4) return "Schedule";
    return step;
  });

  // Calculate the "percent completed" for the bar.
  // Example: If we're at step 1 (index=1) out of 4 steps, that's 1/3 (≈ 33%).
  const stepsCompletedPercentage =
    steps.length > 1 ? (activeStep / (steps.length - 1)) * 100 : 0;

  return (
    <Box data-testid="progress-bar" {...props}>
      {/* Progress Track (gray background) */}
      {/* <Box
        style={{
          position: "relative",
          height: "8px",
          backgroundColor: theme.colors.gray[3],
          borderRadius: "4px",
          overflow: "hidden",
          width: "100%",
        }}
      > */}
      {/* Progress Fill */}
      {/* <Box
          style={{
            position: "absolute",
            height: "100%",
            backgroundColor: theme.colors.blue[6],
            width: `${stepsCompletedPercentage}%`,
            transition: "width 0.3s ease-in-out",
          }}
        />
      </Box> */}

      {/* Step Labels */}
      <Group justify="space-between" gap="xs">
        {steps.map((step, index) => {
          const isActive = index === activeStep;
          return (
            <Box key={step} style={{ textAlign: "center", flex: 1 }}>
              <Text
                size="xs"
                fw={isActive ? 700 : 400}
                c={isActive ? "var(--mantine-color-text)" : "dimmed"}
                mr={index < steps.length - 1 ? "xs" : 0}
                data-testid={`step-${step.toLowerCase()}`}
              >
                {withNumbers && `${index + 1}` + ". "}{step}
              </Text>
            </Box>
          );
        })}
      </Group>
    </Box>
  );
}
