import { Box, Divider, Paper, Table, Text, Title } from "@mantine/core";
import { config } from '../../lib/config-candidate';
import PageHeader from "../Typography/PageHeader";

export default function VoucherSelectionGuide() {

  const roomData = [
    {
      room: "Kitchen",
      twoHr: "✓",
      threeHr: "✓",
      fourHr: "✓",
      sixHr: "✓"
    },
    {
      room: "Bathroom",
      twoHr: "-",
      threeHr: "1",
      fourHr: "2",
      sixHr: "3+"
    },
    {
      room: "Bedroom",
      twoHr: "-",
      threeHr: "1",
      fourHr: "2",
      sixHr: "3+"
    }
  ];

  const roomRows = roomData.map((room, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{room.room}</Table.Td>
        <Table.Td>{room.twoHr}</Table.Td>
        <Table.Td>{room.threeHr}</Table.Td>
        <Table.Td>{room.fourHr}</Table.Td>
        <Table.Td>{room.sixHr}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Box>
      <PageHeader>Convenience Meets Quality</PageHeader>
      <Text fz="sm" c="dimmed">Make your home shine with {config.brand.name}. Enjoy up to 6 hours of premium cleaning with one of our discount vouchers. Book and pay online with ease—it's that simple.</Text>
      <Paper p="lg" shadow="sm" radius="md" my="lg" withBorder bg="gray.0">
        <Box mb="lg">
          <Title order={3} size="h4" fz={{ base: "md", md: "xl" }} ta="center">Choose the Perfect Voucher for Your Home:</Title>
          <Text ta="center" fz="sm">Use the guide below to estimate the time you'll need:</Text>
        </Box>
        <Table highlightOnHover withRowBorders miw="100%" verticalSpacing="sm" fz="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th>2 HR</Table.Th>
              <Table.Th>3 HR</Table.Th>
              <Table.Th>4 HR</Table.Th>
              <Table.Th>6 HR</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {roomRows}
          </Table.Tbody>
        </Table>
      </Paper>
      <Text fz="sm" c="dimmed">Need additional time? With your voucher, extra hours are available at a discounted rate starting at $<Text span inherit data-testid="discounted-rate">{config.pricingAndPromotion.discountedRate.amount.toString()}</Text>/hr.</Text>
      <Divider mt="lg" />
    </Box>
  );
}