import { Title, Stack, Text } from '@mantine/core';
import { useWizardFormContext } from '../wizard-form-context';
import TextInputMaterial from '~/components/form/inputs/TextInputMaterial';
import { useEffect } from 'react';
import { zipCodeFormat } from '../validation';
import ZipCoveredNotice from '~/components/ZipCodeCheck/ZipCoveredNotice';
import ZipNotCoveredNotice from '~/components/ZipCodeCheck/ZipNotCoveredNotice';
import ContactInfoInputs from './StepContactInfo/ContactInfoInputs';
import OptInTermsCheckbox from './StepContactInfo/OptInTermsCheckbox';

const StepContactInfo = () => {
  const { form, zipCodeAvailability, setZipCodeAvailability, checkZipCode } = useWizardFormContext();
  const { getInputProps, errors } = form;

  useEffect(() => {

    try {
      const zipCode = zipCodeFormat.parse(form.values.contactInfo.zipCode);
      checkZipCode({ zipCode });
    } catch (err) {
      // Ignore invalid ZIP codes
      setZipCodeAvailability(null);
    }

  }, [form.values.contactInfo.zipCode]);

  return (
    <div>
      <Title order={2} fz="h4">
        Enter Your Name & Email
      </Title>
      <Stack gap="sm" my="sm">
        {errors.contactInfo && (
          <Text c="red" size="sm">
            {errors.contactInfo}
          </Text>
        )}

        <ContactInfoInputs />

        <TextInputMaterial
          label="ZIP Code"
          placeholder="21211"
          required
          autoComplete="postal-code"
          {...getInputProps('contactInfo.zipCode')}
        />

        {zipCodeAvailability === false && (
          <ZipNotCoveredNotice />
        )}

        {zipCodeAvailability === true && (
          <ZipCoveredNotice />
        )}

        <OptInTermsCheckbox />
      </Stack>
    </div>
  );
};

export default StepContactInfo;