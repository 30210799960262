import { Title, TitleProps } from "@mantine/core";

interface PageHeaderProps extends TitleProps {}

export default function PageSubHeader({ children, ...props }: PageHeaderProps) {
    return (
        <Title order={3} fz={{base:"h5", md:"h4"}} {...props}>
            {children}
        </Title>
    );
}