import { Group, Rating, Text, NumberFormatter, GroupProps } from "@mantine/core";

type ReviewRatingDisplayProps = {
  reviewsRating: number;
  reviewsCount: number;
} & GroupProps;

export default function ReviewRatingDisplay({ reviewsRating, reviewsCount, ...groupProps }: ReviewRatingDisplayProps) {

  return (
    <Group {...groupProps} align="center" gap="sm" data-testid="review-rating-display">
        <Text span fw="bold" fz="lg"><NumberFormatter value={reviewsRating} decimalScale={1} data-testid="rating-decimal"/></Text>
        <Rating value={reviewsRating} size="md" title={reviewsRating.toFixed(1).toString()} readOnly data-testid="rating-stars" />
        <Text span c="dimmed" fz="xs"><NumberFormatter value={reviewsCount} thousandSeparator data-testid="rating-count" /> Ratings</Text>
      </Group>
  );
}