import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Container,
  Text,
  Title,
  List,
  Group,
  Image,
  Anchor,
  Badge,
} from "@mantine/core";
import { useWizardFormContext } from "../wizard-form-context";
import PaymentFields from "~/components/chargebee/PaymentFields";
import { config } from "../../../lib/config-candidate";

interface StepPaymentProps {
  chargebee: any;
  onPaymentComponentReady?: (isReady: boolean) => void;
  onValidatePayment?: (isValid: boolean) => void;
}

export interface StepPaymentRef {
  validatePaymentForm: () => Promise<boolean>;
}

const StepPaymentCustom = forwardRef<StepPaymentRef, StepPaymentProps>(
  ({ chargebee, onPaymentComponentReady, onValidatePayment }, ref) => {
    const {
      form,
      paymentSuccess,
      cart
    } = useWizardFormContext();

    const selectedProduct = cart[form.values.voucher];

    const cartRecord = { [form.values.voucher]: 1 } //Simplified cart since we are not using it for quantity changes

    const subtotal = useMemo(() => {
      if (!selectedProduct) return 0;
      return Number(selectedProduct.price) * (cartRecord[selectedProduct.id] || 1);
    }, [selectedProduct, cartRecord]);

    // Reference to PaymentFields
    const paymentFieldsRef = useRef<any>(null);

    async function validatePaymentForm() {
      // tokenizing the form will validate the payment information
      return await paymentFieldsRef.current?.tokenizeForm();
    }

    useImperativeHandle(ref, () => ({
      validatePaymentForm,
    }));

    const CardIconImage = () => {
      return (
        <Box w={230} h={50} mt="md">
          <Image
            src="/images/4_Card_color_horizontal.png"
            alt="American Express, MasterCard, Visa, and Discover Accepted Here"
          />
        </Box>
      )
    }

    // If the payment has succeeded, let’s skip rendering PaymentFields and show a success message
    if (paymentSuccess) {
      return (
        <Container size="100%" p={0}>
          <Title order={2} fz="h4" c="green" mt="md">
            Payment Successful!
          </Title>
          <Text fz="sm" mt="xs">
            Thank you! Your payment has been processed.
          </Text>
        </Container>
      );
    }

    return (
      <Container fluid p={0}>
        <Group justify="space-between" align="center">
          <Title order={2} fz="h4">Payment Method</Title>
          <Badge size="md" color="green" leftSection={<svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-shield-lock"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
            <path d="M12 11m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M12 12l0 2.5" />
          </svg>}>Secure</Badge>
        </Group>
        <Text fz="sm" my="sm" c="dimmed">Enter your payment details</Text>
        <CardIconImage />

        <PaymentFields
          ref={paymentFieldsRef}
          onPaymentComponentReady={onPaymentComponentReady}
          onTokenization={onValidatePayment}
        />
        <Title order={3} fz="h5" mt="md">Important, Please Read:</Title>
        <List fz="sm" mt="md">
          <List.Item>By continuing, you agree to our <Anchor c="dimmed" td="underline" fz="sm" href={config.legal.privacyPolicyUrl} target="_blank">Privacy Policy</Anchor> and <Anchor c="dimmed" td="underline" fz="sm" href={config.legal.termsOfServiceUrl} target="_blank">Terms & Conditions</Anchor>, which includes an arbitration agreement.</List.Item>
          <List.Item>By redeeming your voucher, you agree to enroll in {config.pricingAndPromotion.membership.name} membership, and be charged $<Text span inherit data-testid="membership-rate">{config.pricingAndPromotion.membership.monthlyRate.amount.toString()}</Text>/mo (taxes may apply) until you cancel.</List.Item>
          <List.Item>Cancel anytime online or by contacting support.</List.Item>
          <List.Item>Canceling within the first <Text span inherit data-testid="membership-minimum-months">{config.pricingAndPromotion.membership.minimumDurationMonths.toString()}</Text>-months will result in an early termination fee <Anchor c="dimmed" td="underline" fz="sm" href={`${config.legal.termsOfServiceUrl}#early-termination`} target="_blank">calculated based on the full price of your first cleaning</Anchor>.</List.Item>
          <List.Item>More information about autorenewal and cancelation terms, <Anchor c="dimmed" td="underline" fz="sm" href={`${config.legal.termsOfServiceUrl}#autorenewal`} target="_blank">click here</Anchor>.</List.Item>
        </List>
      </Container>
    );
  }
);

export default StepPaymentCustom;