import React from 'react';
import { Title, Text, Stack, Box, List, Mark } from '@mantine/core';
import { useWizardFormContext } from '../wizard-form-context';
import { config } from '../../../lib/config-candidate';

const StepNotices = () => {

  return (
    <Box p="lg" style={{ border: "1px solid green", borderRadius: 4 }}>
      <Title order={2} fz="h4" ta="center" mb="sm">
        Enjoy unlimited discounted cleanings for only <Mark>${config.pricingAndPromotion.discountedRate.amount.toString()}/hr - that's a 66% savings!</Mark>
      </Title>
      
      <Stack gap="md">
        <List
        spacing="sm"
        size="sm"
        pl={0}
        icon={
          "✅"
        }
        style={{ paddingLeft: 0 }}
      >
        <List.Item>
          This voucher requires a <Text span inherit data-testid="membership-minimum-months">{config.pricingAndPromotion.membership.minimumDurationMonths.toString()}</Text>-month {config.pricingAndPromotion.membership.name} membership <strong>that renews automatically at $<Text span inherit data-testid="membership-rate">{config.pricingAndPromotion.membership.monthlyRate.amount.toString()}</Text>/month until you cancel. Cancel anytime!</strong>.
        </List.Item>
        <List.Item>
          Early cancellation (before <Text span inherit data-testid="membership-minimum-months">{config.pricingAndPromotion.membership.minimumDurationMonths.toString()}</Text> months) will result in your first cleaning being charged at full price.
        </List.Item>
        <List.Item>
          Book unlimited cleanings at your <strong>exclusive discounted rate of ${config.pricingAndPromotion.discountedRate.amount.toString()}/hr - saving you 66%</strong>.
        </List.Item>
        <List.Item>
          The discount voucher is <strong>fully refundable until used - risk free!</strong>.
        </List.Item>
      </List>
      </Stack>
    </Box>
  );
};

export default StepNotices;