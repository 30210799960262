import { CardComponent } from "@chargebee/chargebee-js-react-wrapper";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  Group,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
  LoadingOverlay,
} from "@mantine/core";
import { useWizardFormContext, WizardFormProvider } from "../form/wizard-form-context";
import { config } from "~/lib/config-candidate";

interface PaymentFieldsProps {
  onPaymentComponentReady?: (isReady: boolean) => void;
  onTokenization?: (isValid: boolean) => void;
}

export interface PaymentFieldsRef {
  tokenizeForm: () => Promise<boolean>;
}

const PaymentFields = forwardRef<PaymentFieldsRef, PaymentFieldsProps>(
  ({ onPaymentComponentReady, onTokenization }, ref) => {
    const component = useRef<any>(null);
    const theme = useMantineTheme();
    const {
      isTokenizing,
      setIsTokenizing,
      isPaymentComponentReady,
      setIsPaymentComponentReady,
      form,
      cleanupPayment,
    } = useWizardFormContext();

    // Cleanup payment component on unmount
    useEffect(() => {
      return () => {
        if (component.current) {
          cleanupPayment();
          component.current = null;
        }
      };
    }, [cleanupPayment]);

    useEffect(() => {
      if (component.current && isPaymentComponentReady) {
        onPaymentComponentReady?.(true);
      }
    }, [component.current, isPaymentComponentReady, onPaymentComponentReady]);

    async function tokenizeForm() {
      if (!component.current) {
        // console.error("Payment component not initialized.");
        onTokenization?.(false);
        return false;
      }
      setIsTokenizing(true);
      try {
        const result = await component.current.tokenize();
        // console.log("Tokenization result:", result);
        // Save the token to your form
        form.setFieldValue("paymentToken", result.token);
        onTokenization?.(true);
        return true;
      } catch (error: any) {
        // console.error("Tokenization error:", error);
        form.setFieldError('paymentToken', error.message);
        onTokenization?.(false);
        onPaymentComponentReady?.(true);
        return false;
      } finally {
        setIsTokenizing(false);
      }
    }

    useImperativeHandle(ref, () => ({
      tokenizeForm,
    }));

    // Field-level error handling if you wish
    const onChange = (status: any) => {
      if (status.empty) {
        form.setFieldError('paymentToken', "Please fill out all fields.");
        return;
      }
      form.setFieldError('paymentToken', status.error?.message || "");
    };

    return (
      <Box pos="relative">
        <LoadingOverlay
          visible={!isPaymentComponentReady || isTokenizing}
          overlayProps={{ radius: 'md', blur: 3 }}
          loaderProps={{ type: 'dots' }}
        />
        <Box
          p="md"
          style={{
            border: `1px solid var(--mantine-color-default-border)`,
            borderRadius: theme.radius.md,
            borderColor: form.errors?.paymentToken
              ? theme.colors.red[7]
              : "var(--mantine-color-default-border)",
          }}
        >
          <CardComponent
            ariaLabel={"Credit Card Form"}
            ref={component}
            onReady={() => setIsPaymentComponentReady(true)}
            onChange={onChange}
            icon={false}
            classes={{
              focus: "payment-field-focus",
              complete: "payment-field-completed",
              invalid: "payment-field-invalid",
              empty: "payment-field-empty",
            }}
            styles={{
              base: {
                color: theme.colors.dark[9],
                fontFamily: theme.fontFamily,
                fontWeight: 400,
                fontSize: "16px",
                "::placeholder": {
                  color: theme.colors.gray[5],
                  fontWeight: 300,
                },
              },
              invalid: {
                color: theme.colors.red[7],
              },
            }}
            placeholder={{
              number: "1234 1234 1234 1234",
              expiry: "MM / YY",
              cvv: "CVV",
            }}
            showTestCards={config.mode === "development"}
          />
        </Box>

        <Text c="red" fz="md" mt="xs">
          {form.errors.paymentToken}
        </Text>

        <WizardFormProvider>
          <Stack gap="sm" mt="md">
            <TextInput
              size="lg"
              labelProps={{ fz: "sm" }}
              radius="md"
              label="Email to send confirmation:"
              type="email"
              placeholder="email@example.com"
              required
              aria-label="Cardholder Email"
              autoComplete="email"
              {...form.getInputProps("contactInfo.email")}
            />
          </Stack>
        </WizardFormProvider>
      </Box>
    );
  }
);

export default PaymentFields;