import { Alert, Anchor, Text } from "@mantine/core";
import { useWizardFormContext } from "../../wizard-form-context";

export default function ExistingUserFoundNotice() {
  const { emailExists } = useWizardFormContext();

  if (!emailExists) return null;

  return (
    <Alert
      color="green"
      radius="md"
      variant="light"
      p="md"
    >
      <Text span fz="sm" mb="sm">An account with this email already exists.{" "}
        <Anchor
          href="https://baltimorehomecleaning.com/login"
          c="dark"
          td="underline"
          fz="sm"
          fw={600}
          target="_blank"
        >
          Sign in
        </Anchor>
      </Text>
    </Alert>
  )
}