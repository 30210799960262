import { Alert, Text } from "@mantine/core";
import { config } from "../../lib/config-candidate";

export default function ZipCoveredNotice() {

  return (
    <Alert color="green" radius="md" variant="light" p="md">
      {config.zipCodeServiceArea.allowedMessage}
    </Alert>
  )
}