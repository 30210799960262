import { Anchor, Checkbox, Text } from "@mantine/core";
import { useWizardFormContext } from "../../wizard-form-context";
import { config } from "../../../../lib/config-candidate";

export default function OptInTermsCheckbox() {
  const { form } = useWizardFormContext();
  const { getInputProps } = form;
  return (
    <Checkbox
      size="xs"
      mt="md"
      label={
        <Text size="xs" c="dimmed">
          I agree to the{' '}
          <Anchor
            href={config.legal.termsOfServiceUrl}
            target="_blank"
            rel="noopener noreferrer"
            c="dimmed"
            td="underline"
            fz="xs"
          >
            Terms of Service
          </Anchor>{' '}
          and{' '}
          <Anchor
            href={config.legal.privacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
            c="dimmed"
            td="underline"
            fz="xs"
          >
            Privacy Policy
          </Anchor>, and consent to receive promotional messages (including emails, phone calls, and SMS). I understand that I can unsubscribe at any time.
        </Text>
      }
      {...getInputProps('contactInfo.agreedToTerms', { type: 'checkbox' })}
      required
    />
  );
}