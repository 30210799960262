import { Box, Divider, Group, Paper, Stack, Text } from "@mantine/core";
import PageHeader from "../Typography/PageHeader";
import FeaturedReview from "./FeaturedReview";
import ReviewList from "./Reviews/List";
import ReviewRatingDisplay from "./Reviews/ReviewRatingDisplay";
import { Button } from "@mantine/core";
import ReviewsModal from "./Reviews/ReviewsModal";
import { config } from "../../lib/config-candidate";
import { useWizardFormContext } from "../form/wizard-form-context";
import { useState } from "react";

export interface Review {
  id: number;
  rating: number;
  review: string;
  customer_first_name: string;
  customer_last_name: string;
  city: string;
  booking_date: string;
  review_date: string;
  review_featured?: boolean;
}

export default function Reviews() {
  const { initialReviewsData } = useWizardFormContext();

  const [opened, setOpened] = useState(false);
  if (!initialReviewsData || initialReviewsData.items.length === 0) {
    return (
      <Stack gap="xs">
        <ReviewsHeader />
        <ReviewRatingDisplay
          reviewsRating={config.content.reviews.fallbackRating}
          reviewsCount={config.content.reviews.fallbackCount}
          mb="md"
          align="center"
        />
        <Text fz="sm" ta="center">
          No reviews available at this time.
        </Text>
      </Stack>
    );
  }

  return (
    <Box data-testid="reviews-container">

      {initialReviewsData.reviews_featured && initialReviewsData.reviews_featured.length > 0 && (
        <Box data-testid="featured-review">
          <FeaturedReview list={initialReviewsData.reviews_featured} />
        </Box>
      )}

      <ReviewsHeader />
      <ReviewRatingDisplay
        data-testid="reviews-rating-display"
        reviewsRating={initialReviewsData.ratings.average}
        reviewsCount={initialReviewsData.ratings.count}
        mb="md"
        align="center"
      />

      <Box data-testid="reviews-list">
        <ReviewList
          list={initialReviewsData.items}
          currentPage={1}
          nextPage={null}
          prevPage={null}
          pageTotal={1}
          onPageChange={() => { }}
          withButtons={false}
        />
      </Box>

      <Button
        data-testid="read-more-reviews-button"
        onClick={() => setOpened(true)}
        color="gray"
        variant="light"
        radius="lg"
      >
        Read more reviews
      </Button>
      <ReviewsModal opened={opened} setOpened={setOpened} />
      <Divider mt="lg" />
    </Box>
  );
}

function ReviewsHeader() {
  return (
    <Box data-testid="reviews-header">
      <PageHeader data-testid="reviews-title">Real Customers, Real Reviews</PageHeader>
      <Paper p="md" my="md" radius="md" bg="gray.0">
        <Group mb="sm" wrap="nowrap" align="center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="icon icon-tabler icons-tabler-filled icon-tabler-star"
            style={{ color: "var(--mantine-color-yellow-filled)" }}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" />
          </svg>
          <Stack gap="sm">
            <Text span fz="sm" fw={500}>100% Verified Reviews</Text>
            <Text fz="sm">All reviews are from verified customers who have purchased home cleaning services from {config.brand.name}.</Text>
          </Stack>
        </Group>
      </Paper>
    </Box>
  );
}