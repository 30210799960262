import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form";
import type { WizardFormData, Product } from "./types";
import { wizardFormSchema, zipCodeFormat } from "./validation";
import { useConfig } from "../../lib/config-context";
import { z } from "zod";
import { Review } from "../sections/Reviews";

// Define the voucher availability interface with consistent naming
export interface VoucherAvailability {
  available: number;
  maxRedemptions: number;
  redemptions: number;
}

// 1) Define what your context will provide
interface WizardFormContextValue {
  form: UseFormReturnType<WizardFormData>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  hasLoadedReviews: boolean;
  loadReviewsData: () => Promise<void>;

  // Payment ephemeral states
  isPaymentComponentReady: boolean;
  setIsPaymentComponentReady: React.Dispatch<React.SetStateAction<boolean>>;
  isTokenizing: boolean;
  setIsTokenizing: React.Dispatch<React.SetStateAction<boolean>>;
  paymentSuccess: boolean;
  setPaymentSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  cleanupPayment: () => void;

  // Survey modal or other ephemeral states
  showSurveyModal: boolean;
  setShowSurveyModal: React.Dispatch<React.SetStateAction<boolean>>;

  // Payment form valid state
  isPaymentFormValid: boolean;
  setIsPaymentFormValid: React.Dispatch<React.SetStateAction<boolean>>;

  // Cart state
  cart: Record<string, Product>;
  setCart: React.Dispatch<React.SetStateAction<Record<string, Product>>>;

  // Email exists state
  emailExists: boolean | null;
  setEmailExists: React.Dispatch<React.SetStateAction<boolean | null>>;

  // Zip code availability
  zipCodeAvailability: boolean | null;
  setZipCodeAvailability: React.Dispatch<React.SetStateAction<boolean | null>>;
  checkZipCode: (options?: { zipCode?: string }) => Promise<boolean>;

  // Reviews
  reviewsRating: number;
  setReviewsRating: React.Dispatch<React.SetStateAction<number>>;
  reviewsCount: number;
  setReviewsCount: React.Dispatch<React.SetStateAction<number>>;

  // Voucher availability: store a mapping keyed by voucher id.
  voucherAvailability: Record<string, VoucherAvailability | null>;
  setVoucherAvailability: React.Dispatch<React.SetStateAction<Record<string, VoucherAvailability | null>>>;

  // Optionally, if you want to keep a separate count mapping:
  vouchersRemainingCount: Record<string, number>;
  setVouchersRemainingCount: React.Dispatch<React.SetStateAction<Record<string, number>>>;

  hasLoadedAvailability: boolean;
  loadAllVoucherAvailability: () => Promise<void>;

  initialReviewsData?: InitialReviewsData;
  csrfToken: string;
}

const WizardFormContext = createContext<WizardFormContextValue | undefined>(undefined);

interface InitialReviewsData {
  items: Review[];
  ratings: {
    average: number;
    count: number;
  };
  reviews_featured: Review[];
}

interface WizardFormProviderProps {
  initialData: {
    sessionData?: Partial<WizardFormData>;
    voucherAvailability?: Record<string, VoucherAvailability>;
    reviews?: InitialReviewsData;
    hasLoadedAvailability?: boolean;
    hasLoadedReviews?: boolean;
  };
  children: React.ReactNode;
  csrfToken: string;
}

export function WizardFormProvider({ children, initialData = { sessionData: {} }, csrfToken }: WizardFormProviderProps) {
  const config = useConfig();

  // Build product list from config
  const buildInitialProductList = useCallback(() => {
    const items = config?.integrations?.chargebee?.items;
    if (!items || typeof items !== 'object') {
      console.error('Chargebee items not properly initialized in config');
      return [];
    }

    try {
      return Object.entries(items)
        .filter(([_, product]) => product !== null && product !== undefined)
        .map(([key, item]) => ({
          id: Number(key),
          title: item.title || '',
          price: item.price || '0',
          originalPrice: item.originalPrice || '0',
          discountPercentage: item.discountPercentage || '0',
          description: item.description || '',
          default: item.default || false,
          mostPopular: item.mostPopular || false,
        }));
    } catch (error) {
      console.error('Error building product list:', error);
      return [];
    }
  }, [config]);

  // 2) Initialize your Mantine form
  const sessionData = initialData?.sessionData || {};
  const form = useForm<WizardFormData>({
    validate: zodResolver(wizardFormSchema),
    initialValues: {
      products: buildInitialProductList(),
      voucher: "",
      checkZipCode: sessionData.checkZipCode || "",
      contactInfo: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        zipCode: sessionData.contactInfo?.zipCode || "",
        agreedToTerms: false,
      },
      readNotices: false,
      paymentToken: null,
    },
  });

  // 3) Store ephemeral states in this context
  const [activeStep, setActiveStep] = useState(0);
  const [isPaymentComponentReady, setIsPaymentComponentReady] = useState(false);
  const [isTokenizing, setIsTokenizing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);

  // Cleanup function for payment flow
  const cleanupPayment = useCallback(() => {
    setIsPaymentComponentReady(false);
    setIsTokenizing(false);
    setPaymentSuccess(false);
  }, []);
  const [isPaymentFormValid, setIsPaymentFormValid] = useState(false);
  const [zipCodeAvailability, setZipCodeAvailability] = useState<boolean | null>(null);
  const [cart, setCart] = useState<Record<string, Product>>({});
  const [emailExists, setEmailExists] = useState<boolean | null>(null);
  const [reviewsRating, setReviewsRating] = useState<number>(
    initialData?.reviews?.ratings?.average || config.content.reviews.fallbackRating
  );
  const [reviewsCount, setReviewsCount] = useState<number>(
    initialData?.reviews?.ratings?.count || config.content.reviews.fallbackCount
  );
  const [voucherAvailability, setVoucherAvailability] = useState<Record<string, VoucherAvailability | null>>(
    () => initialData?.voucherAvailability || {}
  );
  const [vouchersRemainingCount, setVouchersRemainingCount] = useState<Record<string, number>>({});
  const [hasLoadedAvailability, setHasLoadedAvailability] = useState(initialData.hasLoadedAvailability ?? false);
  const [hasLoadedReviews, setHasLoadedReviews] = useState(initialData.hasLoadedReviews ?? false);
  const [reviewsData, setReviewsData] = useState(initialData.reviews);

  // Zip code check function
  const checkZipCode = async ({ zipCode }: { zipCode?: string } = {}) => {
    try {
      const parsedZip = zipCodeFormat.parse(zipCode ?? form.values.checkZipCode);
      form.setFieldValue("contactInfo.zipCode", parsedZip);
      if (parsedZip && config.zipCodeServiceArea.allowedZipCodes.includes(parsedZip)) {
        setZipCodeAvailability(true);
        return true;
      } else {
        setZipCodeAvailability(false);
        form.setFieldError("contactInfo.zipCode", true);
        return false;
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.errors.forEach((fieldError) => {
          form.setFieldError("checkZipCode", fieldError.message);
        });
      }
      setZipCodeAvailability(null);
      return false;
    }
  };

    

  const loadAllVoucherAvailability = useCallback(async () => {
    // Skip if we already have data from server
    if (hasLoadedAvailability || Object.keys(voucherAvailability).length > 0) return;

    try {
      const response = await fetch('/api/voucher-availability-all', {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'X-CSRF-Token': csrfToken,
          'X-Internal-Request': 'true'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.error) {
        console.error('Server returned error:', data.error);
        return;
      }

      setVoucherAvailability(data);

      // Update remaining counts
      const newCounts: Record<string, number> = {};
      Object.entries(data).forEach(([id, availability]) => {
        if (availability) {
          newCounts[id] = availability.available;
        }
      });
      setVouchersRemainingCount(newCounts);

    } catch (err) {
      console.error('Error fetching all voucher availability:', err);
    } finally {
      setHasLoadedAvailability(true); // Mark as loaded regardless of outcome
    }
  }, [hasLoadedAvailability, voucherAvailability, csrfToken]);

  // Initialize reviews data from server-side props
  useEffect(() => {
    if (initialData?.reviews && !reviewsData) {
      setReviewsData(initialData.reviews);
      setReviewsRating(initialData.reviews.ratings?.average ?? config.content.reviews.fallbackRating);
      setReviewsCount(initialData.reviews.ratings?.count ?? config.content.reviews.fallbackCount);
    }
  }, [initialData?.reviews]);

  // Load voucher availability if not loaded
  useEffect(() => {
    if (!hasLoadedAvailability && config?.integrations?.chargebee?.items) {
      loadAllVoucherAvailability();
    }
  }, [hasLoadedAvailability, config, loadAllVoucherAvailability]);

  // Load reviews if not loaded
    

  return (
    <WizardFormContext.Provider
      value={{
        form,
        activeStep,
        setActiveStep,
        initialReviewsData: initialData.reviews,
        isPaymentComponentReady,
        setIsPaymentComponentReady,
        isTokenizing,
        setIsTokenizing,
        paymentSuccess,
        setPaymentSuccess,
        showSurveyModal,
        setShowSurveyModal,
        isPaymentFormValid,
        setIsPaymentFormValid,
        cart,
        setCart,
        zipCodeAvailability,
        setZipCodeAvailability,
        checkZipCode,
        emailExists,
        setEmailExists,
        reviewsRating,
        setReviewsRating,
        reviewsCount,
        setReviewsCount,
        vouchersRemainingCount,
        setVouchersRemainingCount,
        voucherAvailability,
        setVoucherAvailability,
        hasLoadedAvailability,
        loadAllVoucherAvailability,
        hasLoadedReviews,
        
        cleanupPayment,
        csrfToken,
      }}
    >
      {children}
    </WizardFormContext.Provider>
  );
}

export function useWizardFormContext() {
  const context = useContext(WizardFormContext);
  if (!context) {
    throw new Error("useWizardFormContext must be used within a WizardFormProvider");
  }
  return context;
}