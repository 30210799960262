import { json, type LoaderFunction, type ActionFunction, createCookie, redirect } from "@remix-run/node";
import { Review } from "~/components/sections/Reviews";
import { useLoaderData, useFetcher, useRouteError } from "@remix-run/react";
import { useEffect } from "react";
import { clearCartState, getSessionCartState, saveCartState } from "~/services/sessions.server";
import { wizardFormSchema } from '~/components/form/validation';
import type { WizardFormData, ContactInfo } from "~/components/form/types";
import { z } from 'zod';
import {
  findOrCreateCustomer,
  validateEmail,
  trackVoucherClaimed,
  trackProgress,
  trackPaymentSuccess,
  trackPaymentFailure,
  trackMarketingConsent,
  createCustomer,
  trackVoucherInterest,
} from "~/services/customer-io.server";
import { createCouponAsVoucher, getVoucherAvailability, processOrder } from "~/services/chargebee.server";
import { getConfig, getProductById, getInvoiceSpecForProductId } from "~/services/config.server";
import { trackVoucherStockNotification } from "~/services/customer-io.server";
import { Header } from "~/components/Header";
import { VoucherAvailability, WizardFormProvider } from "~/components/form/wizard-form-context";
import Content from "~/components/Content";
import { customerIdCookie, invoiceIdCookie, purchaseAmountCookie } from "~/services/cookies.server";
import { csrfTokenCookie, ensureCsrfToken, validateCsrf } from "~/services/csrf.server";
import { createUser, fetchReviews } from "~/services/xano.server";
import { MantineProvider, TypographyStylesProvider } from "@mantine/core";
import ErrorBoundaryComponent from "~/components/ErrorBoundary";
import { theme } from "~/lib/theme";

// Create an anonymous ID cookie (this could also be a session variable)
const anonIdCookie = createCookie("anon_id", {
  httpOnly: true,
  secure: true,
  sameSite: "lax",
  maxAge: 31536000, // 1 year
});

type LoaderData = {
  csrfToken: string;
  sessionData: Awaited<ReturnType<typeof getSessionCartState>>;
  initialData: {
    voucherAvailability: Record<string, VoucherAvailability>;
    reviews: {
      items: Review[];
      ratings: {
        average: number;
        count: number;
      };
      reviews_featured: Review[];
    };
    hasLoadedAvailability: boolean;
    hasLoadedReviews: boolean;
  };
};

// Helper function for fetching with timeout and error handling
async function fetchWithTimeout(url: URL, options: RequestInit, timeout = 10000) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, { ...options, signal: controller.signal });
    clearTimeout(timeoutId);

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Fetch error: ${response.status} - ${errorText}`);
      return { data: null, error: `HTTP error: ${response.status}` };
    }

    const data = await response.json();

    if (data.error) {
      return { data: null, error: data.error };
    }

    return { data, error: null };
  } catch (error: any) {
    clearTimeout(timeoutId);
    if (error.name === 'AbortError') {
      console.error("Fetch aborted:", url.toString());
      return { data: null, error: 'Fetch aborted' };
    }
    console.error("Fetch failed:", error);
    return { data: null, error: error.message || 'Fetch failed' };
  }
}

export const loader: LoaderFunction = async ({ request }) => {
  const config = await getConfig();
  const csrfToken = await ensureCsrfToken(request);
  let anonId = (await anonIdCookie.parse(request.headers.get("Cookie"))) || "";

  if (!anonId) {
    anonId = crypto.randomUUID();
  }

  // Get session data with empty fallback
  let sessionData = {};
  try {
    sessionData = await getSessionCartState(request);
  } catch (error) {
    console.error("Error retrieving session data:", error);
  }

  try {
    // Fetch reviews
    const reviewsResponse = await fetchReviews(1, 5, 'front');

    // Get vouchers and config
    const items = config.integrations.chargebee.items;
    const voucherIds = Object.keys(items);

    // Build voucher availability map
    const voucherAvailabilityResponse: Record<string, { available: number; maxRedemptions: number; redemptions: number }> = {};

    await Promise.all(
      voucherIds.map(async (id) => {
        const voucher = items[id];
        if (voucher && voucher.invoiceSpec?.coupon_ids?.[0]) {
          const couponId = voucher.invoiceSpec.coupon_ids[0];
          try {
            const availability = await getVoucherAvailability(couponId);
            voucherAvailabilityResponse[id] = availability;
          } catch (err) {
            console.error(`Error fetching availability for voucher ${id}:`, err);
            voucherAvailabilityResponse[id] = { available: 0, maxRedemptions: 100, redemptions: 100 };
          }
        }
      })
    );

    const hasLoadedReviews = reviewsResponse?.items?.length > 0;

    // Set both cookies in response headers
    // @todo: implement this cookie-setting pattern in survey.tsx
    const headers = new Headers();
    headers.append("Set-Cookie", await anonIdCookie.serialize(anonId));
    headers.append("Set-Cookie", await csrfTokenCookie.serialize(csrfToken));

    return json<LoaderData>({
      csrfToken,
      sessionData,
      initialData: {
        voucherAvailability: voucherAvailabilityResponse,
        reviews: reviewsResponse,
        hasLoadedAvailability: Object.keys(voucherAvailabilityResponse).length > 0,
        hasLoadedReviews
      }
    }, {
      headers,
    });
  } catch (error) {
    console.error('Loader error:', error);
    return json<LoaderData>({
      csrfToken,
      sessionData,
      initialData: {
        voucherAvailability: {},
        reviews: {
          items: [],
          ratings: {
            average: config.content.reviews.fallbackRating,
            count: config.content.reviews.fallbackCount
          },
          reviews_featured: []
        },
        hasLoadedAvailability: false,
        hasLoadedReviews: false
      }
    }, {
      headers: new Headers({
        "Set-Cookie": await anonIdCookie.serialize(anonId)
      }),
    });
  }
};

// Action: Handle form submissions for each step or the final submission
export const action: ActionFunction = async ({ request }) => {
  // Validate CSRF token
  await validateCsrf(request);

  const formData = await request.formData();
  const action = formData.get("_action")?.toString();
  const step = formData.get("step")?.toString() || "";
  const rawWizardData = formData.get("data")?.toString();
  const mockOrderSubmission = false; // process.env.NODE_ENV === "development";
  // console.log("Form Action:", action, "Step:", step, "Data:", rawWizardData);

  // Parse the current wizard data with robust error handling
  let wizardData: Partial<WizardFormData> = {};
  try {
    wizardData = rawWizardData ? JSON.parse(rawWizardData) : {};
  } catch (parseError) {
    return json(
      { error: "Failed to parse wizard data: " + String(parseError) },
      { status: 400 }
    );
  }

  const session = await getSessionCartState(request);

  switch (action) {
    case "next":
      try {
        // Dynamically validate only the fields for the current step using Zod
        const stepSchemaMap = {
          voucher: wizardFormSchema.pick({ voucher: true }),
          contact: wizardFormSchema.pick({ contactInfo: true }),
          notices: wizardFormSchema.pick({ readNotices: true }),
          payment: wizardFormSchema.pick({ paymentToken: true }),
        };
        const stepSchema = stepSchemaMap[step as keyof typeof stepSchemaMap];
        stepSchema.parse(wizardData);

        // Track progress for each step
        if (wizardData?.contactInfo?.email) {
          await trackProgress(wizardData.contactInfo.email, step, wizardData);
        }

        // Handle special cases for specific steps
        if (step === "contact" && wizardData.contactInfo) {
          // Create a customer record
          // const customer = await createCustomer(wizardData.contactInfo);
          // Update the session with the customer ID
          // wizardData.contactId = customer.customerId;

          const validationResult = await validateEmail(wizardData.contactInfo.email);

          if (!validationResult.success) {
            // If they’re an existing member or already purchased, return early with success: false
            return json(
              {
                success: false,
                error: validationResult.message, // e.g. "existing_member" or "already_purchased"
              },
              { status: 200 }
            );
          }
          const selectedProduct = wizardData.products?.find(
            (product: any) => product.id.toString() === wizardData.voucher
          );

          // Enforce E.164 phone number format
          if (wizardData.contactInfo.phone) {
            wizardData.contactInfo.phone = "+1" + wizardData.contactInfo.phone.replace(/\D/g, "").slice(-10);
          }

          const contact = await findOrCreateCustomer(wizardData.contactInfo);
          wizardData.contactInfo.contactId = contact.cio_id;

          if (selectedProduct) {
            await trackVoucherClaimed(wizardData.contactInfo.email, selectedProduct);
          }

          // if (wizardData.contactInfo.agreedToTerms) {
          //   await trackMarketingConsent(
          //     wizardData.contactInfo.email,
          //     true
          //   );
          // }
        }

        const updatedState = {
          ...session,
          ...wizardData,
          lastStep: step,
          lastUpdated: new Date().toISOString(),
        };
        const cookie = await saveCartState(updatedState, request);

        return json({ success: true, formData: updatedState }, {
          headers: { "Set-Cookie": cookie },
        });
      } catch (error) {
        if (error instanceof z.ZodError) {
          const errorMessages = error.errors.map((e) => e.message);
          return json({ success: false, errors: errorMessages }, { status: 422 });
        }
        return json(
          { success: false, error: error instanceof Error ? error.message : "Validation Failed" },
          { status: 500 }
        );
      }

    case "submit":
      try {
        // Validate the entire form on submission
        wizardFormSchema.parse(wizardData);

        if (!wizardData.paymentToken) {
          throw new Error("Payment information is required.");
        }

        if (mockOrderSubmission) {
          // ─────────────────────────────────────────────────
          // Mock final success logic
          // ─────────────────────────────────────────────────
          // For demonstration: generate a fake order ID, get the existing
          // contact ID or a default, and track the selected product's price.
          const mockOrderId = "MOCK_ORDER_12345";
          const mockCustomerId = wizardData.contactId || "MOCK_CUST_888";
          // If your wizardData includes the selected product and/or purchase amount:
          const mockPurchaseAmount = "9"; // Or dynamic from wizardData

          // 1) Clear the session state
          // 2) `saveCartState({}, request)` => so the client-side session is cleared
          // 3) Also set cookies for orderId, customerId, purchaseAmount
          const clearedSessionCookie = await saveCartState({}, request);

          const orderIdCookie = `cb_oid=${mockOrderId}; Path=/;`;
          const customerIdCookie = `cb_cid=${mockCustomerId}; Path=/;`;
          const amountCookie = `cb_amount=${mockPurchaseAmount}; Path=/;`;

          // Return a JSON success response (instead of redirect)
          return json(
            {
              success: true,
              orderId: mockOrderId,
              customerId: mockCustomerId,
              purchaseAmount: mockPurchaseAmount,
            },
            {
              headers: {
                "Set-Cookie": [
                  // Clears the session
                  clearedSessionCookie,
                  // Tracks the new order info
                  orderIdCookie,
                  customerIdCookie,
                  amountCookie,
                ].join(', '),
              },
            }
          );
        }

        const { contactInfo, voucher, paymentToken } = wizardData;
        const { customerId, invoiceId } = wizardData.paymentAttempt || {};
        const productId = Number(voucher);

        // Get product from config service
        const product = await getProductById(productId);

        if (!product) {
          return json({ success: false, error: "Invalid product." }, { status: 400 });
        }

        if (!contactInfo) {
          throw new Error("Contact information is required.");
        }

        // 2) Attempt the order
        // Instead of passing user’s “voucher.invoice” to processOrder,
        // we pass the invoiceSpec from config:
        const invoiceSpec = await getInvoiceSpecForProductId(productId);
        if (!invoiceSpec) {
          throw new Error("Invoice specification not for product.");
        }
        const result = await processOrder(contactInfo as ContactInfo, invoiceSpec, paymentToken, customerId, invoiceId);

        if (result.success && result.invoice) {
          if (contactInfo?.email) {
            // Track the successful payment
            await trackPaymentSuccess(contactInfo.email, {
              invoiceId: result.invoiceId,
              product: product.itemPriceId,
              amount: result.invoice?.amount_paid ? (result.invoice.amount_paid / 100).toString() : "0",
              productType: "voucher",
            });
          }

          // Create a voucher for the customer
          const voucher = await createCouponAsVoucher(
            result.invoice
          );

          if (!voucher) {
            throw new Error("Failed to create voucher.");
          }

          // Create a customer record
          const user = await createUser({
            email: contactInfo.email,
            first_name: contactInfo.firstName,
            last_name: contactInfo.lastName,
            phone: contactInfo.phone,
            role: "customer",
            chargebee_customer_id: result.invoice.customer_id,
          });

          if (!user) {
            throw new Error("Failed to create user.");
          }

          // Store order details in the session
          const updatedState = {
            email: contactInfo?.email,
            customerId: result.customerId,
            orderId: result.invoiceId,
            purchaseAmount: result.invoice?.amount_paid ? (result.invoice.amount_paid / 100).toString() : "0",
            userId: user.id,
          };

          const sessionCookie = await saveCartState(updatedState, request);

          // Clean up any pending requests before redirect
          await Promise.resolve(); // Allow any pending state updates to complete

          return json(
            {
              success: true,
              email: contactInfo?.email,
              orderId: result.invoiceId,
              customerId: result.customerId,
              purchaseAmount: result.invoice?.amount_paid ? (result.invoice.amount_paid / 100).toString() : "0",
              userId: user.id,
            },
            {
              headers: {
                "Set-Cookie": sessionCookie,
              }
            }
          );

        } else {
          if (contactInfo?.email) {
            await trackPaymentFailure(contactInfo.email, {
              invoiceId: result.invoiceId,
              error: result.error,
              reason: result.reason,
            });
          }

          const failedAttempts = wizardData.paymentAttempt?.failedAttempts
            ? wizardData.paymentAttempt.failedAttempts + 1
            : 1;
          wizardData.paymentAttempt = {
            customerId: result.customerId,
            invoiceId: result.invoiceId,
            timestamp: new Date().toISOString(),
            failedAttempts,
          };

          wizardData.paymentToken = null;

          const updatedState = {
            ...session,
            ...wizardData,
            lastStep: step,
            lastUpdated: new Date().toISOString(),
          };
          const cookie = await saveCartState(updatedState, request);

          return json(
            {
              success: false,
              action: "submit",
              error: result.error,
              reason: result.reason,
              formData: updatedState,
            },
            { status: 400, headers: { "Set-Cookie": cookie } }
          );
        }

      } catch (error) {
        if (error instanceof z.ZodError) {
          const errorMessages = error.errors.map((e) => e.message);
          return json({ success: false, action: "submit", errors: errorMessages }, { status: 422 });
        }
        return json(
          {
            success: false,
            action: "submit",
            error: error instanceof Error ? error.message : "Submission Failed",
          },
          { status: 500 }
        );
      }

    case "interested":
      try {
        // Validate the contactInfo step
        if (!wizardData.contactInfo) {
          throw new Error("Contact information is required.");
        }
        await wizardFormSchema.pick({ contactInfo: true }).parse(wizardData);
        const contact = await createCustomer(wizardData.contactInfo);
        // console.log("Customer created:", contact);
        const trackVoucherInterestResult = await trackVoucherInterest(wizardData.contactInfo.email, wizardData.contactInfo);
        // console.log("trackVoucherInterestResult:", trackVoucherInterestResult);

        const updatedState = {
          ...session,
          ...wizardData,
          lastStep: step,
          lastUpdated: new Date().toISOString(),
        };
        const cookie = await saveCartState(updatedState, request);

        return redirect("/thank-you-interest", {
          headers: { "Set-Cookie": cookie },
        });

      } catch (error) {
        if (error instanceof z.ZodError) {
          const errorMessages = error.errors.map((e) => e.message);
          return json({ success: false, errors: errorMessages }, { status: 422 });
        }
        return json(
          { success: false, error: error instanceof Error ? error.message : "Validation Failed" },
          { status: 500 }
        );
      }

    case "stockNotification":
      // The user clicked “Notify me when it's back”.
      // We'll need the voucher ID or product info.
      const voucherId = formData.get("voucherId")?.toString();
      const userEmail = wizardData.contactInfo?.email;

      console.log("User interested in stock notification for voucher:", voucherId, "email:", userEmail);

      if (!voucherId) {
        return json({ success: false, error: "No voucherId provided." }, { status: 400 });
      }
      if (!userEmail) {
        return json({ success: false, error: "No email found in form data." }, { status: 400 });
      }

      try {
        // Get voucher details from config service
        const voucher = await getProductById(Number(voucherId));

        if (!voucher) {
          return json({ success: false, error: "Voucher not found." }, { status: 404 });
        }

        await trackVoucherStockNotification(userEmail, {
          id: voucherId,
          title: voucher.title,
          price: voucher.price,
        });
        return json({ success: true, notifyNextDropRequested: true, }, { status: 200 });
      } catch (error) {
        console.error('Error processing stock notification:', error);
        return json({ success: false, error: "Failed to track stock notification." }, { status: 500 });
      }

    default:
      return json({ error: "Unknown _action: " + action }, { status: 400 });
  }

};

// @todo: Improve the UI for this error boundary (and others)
export function ErrorBoundary() {
  const error = useRouteError();
  const isNetworkError = error instanceof Error && error.message.includes('socket hang up');

  // For route-level errors, don't include document structure
  return (
    <MantineProvider theme={theme}>
      <TypographyStylesProvider>
        <ErrorBoundaryComponent
          error={error instanceof Error ? error : undefined}
          heading={isNetworkError ? "Connection Error" : undefined}
          message={isNetworkError ?
            "There was a problem with the network connection. Your payment may have been processed successfully. Please check your email for confirmation or contact support if you're unsure about your order status." :
            undefined
          }
        />
      </TypographyStylesProvider>
    </MantineProvider>
  );
}

export default function Index() {
  const { sessionData, initialData, csrfToken } = useLoaderData<typeof loader>();

  return (
    <>
      <Header />
      <WizardFormProvider
        initialData={{
          sessionData,
          ...initialData
        }}
        csrfToken={csrfToken}
      >
        <Content sessionData={sessionData} />
      </WizardFormProvider>
    </>
  );
}