import { Modal, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { ReactNode } from "react";

interface ModalBaseProps {
  children: ReactNode;
  title?: string;
  opened: boolean;
  setOpened: (opened: boolean) => void;
  size?: string;
  radius?: string;
  padding?: string;
  transitionProps?: {
    transition: string;
    duration: number;
  };
  header?: ReactNode;
  [key: string]: any;
}

// @todo: Replace custom header with Mantine's Modal Compound Components

export default function ModalBase({ children, title = "Modal Title", opened, setOpened, size = "lg", radius = "lg", padding = "md", transitionProps = { transition: 'fade', duration: 200 }, header, ...props }: ModalBaseProps) {

  const isMobile = useMediaQuery('(max-width: 62em)', false, {
    getInitialValueInEffect: false,
  });

  if (header) {
    return (
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={header}
        size={size}
        radius={radius}
        padding={padding}
        fullScreen={isMobile}
        transitionProps={transitionProps}
        {...props}
      >
        {children}
      </Modal>
    );
  }

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title={<Text span fw="bold" ta="center" fz="lg">{title}</Text>}
      size={size}
      radius={radius}
      padding={padding}
      fullScreen={isMobile}
      transitionProps={transitionProps}
      {...props}
    >
      {children}
    </Modal>
  );
}