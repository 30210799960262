import { Stack, Text, Title, useMantineTheme } from "@mantine/core";
import ZipCodeFormCombined from "../ZipCodeCheck/ZipCodeForm";


export default function ServiceArea() {

  const theme = useMantineTheme();

  return (
    <Stack gap="sm">
      <Title order={2} fz={{base:"h5", md:"h4"}}>Service Area</Title>
      <Text fz="sm">Find out if you qualify for this offer—enter your zip code below.</Text>
      <ZipCodeFormCombined />
    </Stack>
  );
}