import { z } from 'zod';

export const voucherSchema = z.object({
  voucher: z.string().min(1, 'Please select a voucher'),
});

export const zipCodeFormat = z.string().max(5, 'ZIP code is invalid').regex(/^\d{5}$/, 'ZIP code is invalid').nonempty('ZIP code is required');

export const contactSchema = z.object({
  contactInfo: z.object({
    firstName: z.string().min(1, 'First name is required').max(50, 'First name is too long').nonempty('First name is required'),
    lastName: z.string().min(1, 'Last name is required').max(50, 'Last name is too long').nonempty('Last name is required'),
    email: z.string().email('Invalid email address').nonempty('Email is required'),
    phone: z.string().min(10, 'Valid phone number is required'),
    // zipCode: z.string()
    // .startsWith('212', 'Sorry, this offer isn\'t available in your area yet.').max(5, 'ZIP code is invalid').regex(/^\d{5}$/, 'ZIP code is invalid').nonempty('ZIP code is required'),
    zipCode: zipCodeFormat,
    // .refine(
    //   (value) => {
    //     if (!value) {
    //       return false
    //     }

    //     if (value.length < 5) {
    //       return false;
    //     }
    //     // Mock availability check
    //     if (value && value.startsWith('212')) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   (value) => ({ message: value ? value.length < 5 ? "" : `Sorry, this offer isn't available in ${value ? value : "your area"} yet.` : 'Valid ZIP code is required' })
    // ),
    agreedToTerms: z.boolean().refine((val) => val === true, {
      message: 'You must agree to the terms',
    }),
  }),
});

export const noticesSchema = z.object({
  // readNotices: z.boolean().refine((val) => val === true, {
  //     message: 'You must acknowledge the notices',
  // }),
});

export const paymentIntentSchema = z.object({
  id: z.string(),
  // Add any additional fields you expect in the paymentIntent object
  // For example:
  // amount: z.number(),
  // currency: z.string(),
}).catchall(z.any()); // Allow any additional fields

export const paymentSchema = z.object({
  // paymentIntent: paymentIntentSchema,
  paymentToken: z.string().min(1, 'Payment information is required'),
});

export const wizardFormSchema = z.object({
  voucher: voucherSchema.shape.voucher,
  contactInfo: contactSchema.shape.contactInfo,
  // readNotices: noticesSchema.shape.readNotices,
  // paymentIntent: paymentSchema.shape.paymentIntent,
  paymentToken: paymentSchema.shape.paymentToken,
});

export const validateStep = async (
  step: string,
  data: any
): Promise<{ success: boolean; error?: string }> => {
  try {
    switch (step) {
      case 'voucher':
        await voucherSchema.parseAsync(data);
        break;
      case 'contact':
        await contactSchema.parseAsync(data);
        break;
      case 'notices':
        await noticesSchema.parseAsync(data);
        break;
      case 'payment':
        await paymentSchema.parseAsync(data);
        break;
    }
    return { success: true };
  } catch (error) {
    if (error instanceof z.ZodError) {
      // console.log(error.errors);
      return {
        success: false,
        error: error.errors[0].message,
      };
    }
    return {
      success: false,
      error: 'An unexpected error occurred',
    };
  }
};